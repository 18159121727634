import React from "react";
import {ConfirmationDialog} from "./ConfirmationDialog";
import {useTranslation} from "react-i18next";

interface Props {
    isOpen: boolean,
    onCancel: () => void,
    onConfirm: () => void
}

export const BookingDeletionConfirmationDialog: React.FC<Props> = (props) => {
    const {t} = useTranslation();
    return (
        <ConfirmationDialog title={t("delete-dialog-title")} {...props}>
            <p>{t("delete-Booking-Confirmation")}</p>
        </ConfirmationDialog>
    );
}