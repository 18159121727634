import {Button, ButtonProps, styled} from "@material-ui/core";
import React from "react";

// Center start icon
const StyledButton = styled(Button)({
    "& .MuiButton-startIcon": {margin: "0"},
    minWidth: 0,
});


// MUI has an icon button, but it does not have the variant "contained" to show colored background
export const CustomIconButton = ({children, icon, ...rest}: ButtonProps & { icon: React.ReactNode }) => {
    return <StyledButton variant={"contained"} color={"primary"} size={"small"} startIcon={icon} {...rest}/>
}