import dayjs from "dayjs";

const TIME_RANGE_SEPARATOR = "\u202F\u2013\u202F";
const NO_TIME = "--:--";

// used for begin and end of bookings
function formatDateStringAsTime(date: string) {
    return dayjs(date).format("HH:mm");
}

// used for begin and end of bookings
export function formatTimeRange(dateStartTime: string | null | undefined, dateEndTime: string | null | undefined){
    const start = (dateStartTime === null || dateStartTime === undefined) ? NO_TIME : formatDateStringAsTime(dateStartTime);
    const end = (dateEndTime === null || dateEndTime === undefined) ? NO_TIME : formatDateStringAsTime(dateEndTime);
    return start + TIME_RANGE_SEPARATOR + end;
}