import React, {createContext, useMemo, useState} from "react";
import {Booking} from "../../API";

export interface EditSeatBookingContextType {
    bookingToEdit: Booking | null,
    setSeatBookingToEdit: (value: Booking | null) => void,
    isBookingEditDialogOpen: boolean
    setIsBookingEditDialogOpen: (value: boolean) => void
}

//Used for seat booking edit dialog, that is opened from room plan view
export const EditSeatBookingFromPlanContext = createContext<EditSeatBookingContextType | null>(null);

export const EditSeatBookingContextProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [bookingToEdit, setBookingToEdit] = useState<Booking | null>(null);
    const [isBookingEditDialogOpen, setIsBookingEditDialogOpen] = useState<boolean>(false);

    function setSeatBookingToEdit(value: Booking | null) {
        setBookingToEdit(value);
    }

    function setIsDialogOpen(value: boolean) {
        setIsBookingEditDialogOpen(value);
    }

    const values : EditSeatBookingContextType = useMemo(() => {
        return {
            bookingToEdit: bookingToEdit,
            setSeatBookingToEdit: setSeatBookingToEdit,
            isBookingEditDialogOpen: isBookingEditDialogOpen,
            setIsBookingEditDialogOpen: setIsDialogOpen
        }
    }, [bookingToEdit, isBookingEditDialogOpen])

    return (
        <EditSeatBookingFromPlanContext.Provider value={values}>
            {children}
        </EditSeatBookingFromPlanContext.Provider>);
}