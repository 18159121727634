import dayjs, {Dayjs} from "dayjs";
import {useMemo} from "react";

export interface TimeSlot {
    start: Dayjs,
    end: Dayjs
}

interface ItemHHMM {
    begin: string, // HH:mm
    end: string    // HH:mm
}

function getFreeTimeWindows(startHHMM: string, endHHMM: string, blockedSlots: ItemHHMM[]){
    // not all cases handled, there should be no intersections and all slots should be in the expected range
    let current = startHHMM;
    const freeTimeWindows: ItemHHMM[] = [];
    const sortedByBegin = [...blockedSlots].sort((a,b) => a.begin.localeCompare(b.begin));
    sortedByBegin.forEach((item) => {
        const {begin, end} = item;
        if (begin > current){
            freeTimeWindows.push({begin: current ,end: begin})
        }
        current = end;
    })
    if (current < endHHMM) {
        freeTimeWindows.push({begin: current,end: endHHMM});
    }
    return freeTimeWindows;
}

// similar to useGetFreeTimeWindows
export function useFreeTimeWindows(itemsOfTheDay: TimeSlot[], startWorkDayHHmm = "00:00", endWorkDayHHmm = "23:59") {
    return useMemo<TimeSlot[]>(() => {

        const timeAsStrings: ItemHHMM[] = [...itemsOfTheDay].map((item) => {
           return {begin: item.start.format("HH:mm"), end: item.end.format("HH:mm")};
        });

        const freeTimeWindows = getFreeTimeWindows(startWorkDayHHmm, endWorkDayHHmm, timeAsStrings);

        return freeTimeWindows.map((item) => {
            return {start: dayjs(item.begin, "HH:mm"), end: dayjs(item.end, "HH:mm")};
        });
    }, [itemsOfTheDay, startWorkDayHHmm, endWorkDayHHmm])
}