import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Neighborhood, Room} from "../API";
import {useNeighborhoodList} from "../hooks/useNeighborhoodList";
import MultiSelectComboBox from "./MultiselectCombobox";
import {gql, useMutation} from "@apollo/client";
import {updateRestrictedNeighborhoods} from "../graphql/mutations";
import {useRoleList} from "../hooks/useRoleList";
import ConfirmationDialog from "./ConfirmationDialog";
import {useErrorContext} from "../hooks/useErrorContext";
import InfoDialog from "./InfoDialog";
import {reformatNeighborhoodId} from "../Utils/Helpers";
import {usePermissionHelper} from "../hooks/usePermissionHelper";
import {useDeviceMediaType} from "../hooks/useDeviceMediaType";
import CloseButton from "./Buttons/CloseButton";

interface Props {
    showNeighborhoodManager: boolean;
    setShowNeighborhoodManager: (value: boolean) => void;
    selectedRoom: Room;
}

const NeighborhoodManagerComponent: React.FC<Props> = (props) => {
    const {
        showNeighborhoodManager,
        setShowNeighborhoodManager,
        selectedRoom,
    } = props;
    const MAX_NEIGHBORHOOD_NAME_LENGTH = 50;
    const {reportError} = useErrorContext();
    const permissionHandler = usePermissionHelper();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const {t} = useTranslation();
    const [neighborhoods, refetchNeighborhoods] = useNeighborhoodList(selectedRoom?.roomId);
    const [updateRestrictedNeighborhoodsMutation] = useMutation(gql(updateRestrictedNeighborhoods));
    const [localNeighborhoods, setLocalNeighborhoods] = useState<Neighborhood[]>(neighborhoods.filter(n => permissionHandler.hasManagementForNeighborhood(n)));
    const {roles: availableRoles} = useRoleList();

    const [isNameEmptyDialogOpen, setIsNameEmptyDialogOpen] = useState(false);
    const [isNameCollisionDialogOpen, setIsNameCollisionDialogOpen] = useState(false);

    const {isNoFullscreen, isMobile} = useDeviceMediaType()

    useEffect(() => {
        setLocalNeighborhoods(neighborhoods.filter(n => permissionHandler.hasManagementForNeighborhood(n)));
    }, [neighborhoods]);

    const handleRolesChange = (selectedRoles: string[], neighborhoodId: string, context: "access" | "admin") => {
        const fieldName = context === "access" ? "roleIds" : "adminRoleIds";
        const temp = localNeighborhoods.map(neighborhood => {
                if (neighborhood.neighborhoodId === neighborhoodId) {
                    const newNeighborhood: Neighborhood = {
                        ...neighborhood,
                        [fieldName]: selectedRoles.map(role => {
                            return availableRoles.find(roleObject => roleObject.roleName === role)?.roleId;
                        }).filter((roleName): roleName is string => Boolean(roleName))
                    }
                    return newNeighborhood;
                } else {
                    return neighborhood;
                }
            }
        )
        setLocalNeighborhoods(temp);
    };

    const handleIsRestricted = (neighborhoodId: string) => {
        const temp = localNeighborhoods.map(neighborhood => {
                if (neighborhood.neighborhoodId === neighborhoodId) {
                    const newNeighborhood: Neighborhood = {
                        ...neighborhood,
                        restricted: !neighborhood.restricted,
                        roleIds: []
                    }
                    return newNeighborhood;
                } else {
                    return neighborhood;
                }
            }
        )
        setLocalNeighborhoods(temp);
    }

    const handleClose = () => {
        setShowNeighborhoodManager(false);
    };

    const getNeighborhoodsInput = (neighborhood: Neighborhood) => {
        return {
            neighborhoodId: neighborhood.neighborhoodId,
            name: neighborhood.name,
            roomId: neighborhood.roomId,
            restricted: neighborhood.restricted,
            roleIds: neighborhood.roleIds || [],
            adminRoleIds: neighborhood.adminRoleIds || []
        }
    }

    async function updateNeighborhoodsAndDeleteBookings(neighborhoods: Neighborhood[]): Promise<boolean> {
        let neighborhoodsInputs = neighborhoods.map(value => getNeighborhoodsInput(value))
        const response = await updateRestrictedNeighborhoodsMutation({
            variables: {
                input: {
                    neighborhoods: neighborhoodsInputs
                }
            }
        }).catch((err) => reportError(err, "", "NeighborhoodManagerComponent - updateRestrictedNeighborhoodsMutation"))

        if (response?.data?.updateRestrictedNeighborhoods?.statusCode === 200) {
            return true;
        }
        throw new Error("Unable to update neighborhood. Please try again.");
    }

    const handleNeighborhoodNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, neighborhoodId: string) => {
        const newNeighborhoodName = event.target.value.trimStart();
        setLocalNeighborhoods(prevNeighborhoods =>
            prevNeighborhoods.map(neighborhood => {
                if (neighborhood.neighborhoodId === neighborhoodId) {
                    return {...neighborhood, name: newNeighborhoodName}
                } else {
                    return neighborhood;
                }
            })
        );
    };

    const checkNameChanges = (): boolean => {
        // Check if the name is not empty:
        for (const neighborhood of localNeighborhoods) {
            if (neighborhood.name?.trim() === "") {
                setIsNameEmptyDialogOpen(true);
                return false;
            }
        }
        // Check for duplicate neighborhood names
        const neighborhoodNames = localNeighborhoods.map(n => n.name?.trim());
        const uniqueNeighborhoodNames = new Set(neighborhoodNames);
        if (neighborhoodNames.length !== uniqueNeighborhoodNames.size) {
            setIsNameCollisionDialogOpen(true);
            return false;
        }
        return true;
    }

    const handleSave = async () => {
        try {
            await updateNeighborhoodsAndDeleteBookings(localNeighborhoods);
            refetchNeighborhoods();
        } catch (err) {
            if (err instanceof Error) {
                reportError(err, err.message, "NeighborhoodManagerComponent handleSave");
            } else {
                console.error("An unknown error occurred:", err);
            }
        }

        setShowNeighborhoodManager(false);
    };

    function hasNeighborhoodNewRestrictions(neighb: Neighborhood, changedNeighb: Neighborhood | undefined): boolean {
        return (!neighb.restricted && changedNeighb && changedNeighb.restricted)
            || (neighb.restricted && changedNeighb && changedNeighb.restricted
                && neighb.roleIds?.some(role => !changedNeighb.roleIds?.includes(role))) as boolean;
    }

    function isNeighborhoodListChanged(neighborhoods: Neighborhood[], changedNeighborhoods: Neighborhood[]): boolean {
        for (const nhb of neighborhoods) {
            const chNhb = changedNeighborhoods.find(chNhb => chNhb.neighborhoodId === nhb.neighborhoodId);
            if (hasNeighborhoodNewRestrictions(nhb, chNhb)) {
                return true;
            }
        }
        return false;
    }

    const checkChanges = () => {

        const nameChecksPassed = checkNameChanges();
        if (!nameChecksPassed) {
            return;
        }

        const changed = isNeighborhoodListChanged(neighborhoods, localNeighborhoods);
        if (changed) {
            setShowConfirmationDialog(true);
        } else {
            handleSave();
        }
    }

    const getRoleNames = (roleIds: string[] | null | undefined) => {
        if (!roleIds) {
            return [];
        }
        return roleIds.map(getRoleNameById).filter((roleName): roleName is string => Boolean(roleName)) ?? [];

        function getRoleNameById(id: string) {
            return availableRoles.find(roleObject => roleObject.roleId === id)?.roleName;
        }
    }

    const tableCellRoleStyle = {
        minWidth: 350,
        maxWidth: 350,
        width: '-webkit-fill-available'
    }

    return (
        <>
            <InfoDialog
                open={isNameCollisionDialogOpen}
                onClose={() => setIsNameCollisionDialogOpen(false)}
                title={t('neighborhood_name_collision_title')}
                content={t('neighborhood_name_collision_text')}
            />

            <InfoDialog
                open={isNameEmptyDialogOpen}
                onClose={() => setIsNameEmptyDialogOpen(false)}
                title={t('neighborhood_name_empty_title')}
                content={t('neighborhood_name_empty_text')}
            />

            <ConfirmationDialog isOpen={showConfirmationDialog}
                                setIsOpen={setShowConfirmationDialog}
                                title={t("neighborhood_confirm_overwrite")}
                                onCancel={() => {
                                }}
                                onOk={handleSave}>
            </ConfirmationDialog>
            <Dialog
                open={showNeighborhoodManager}
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle>{t("neighborhood_management_dialog-title")}</DialogTitle>
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("neighborhoods")}</TableCell>
                                <TableCell>{t("neighborhood_restricted_label")}</TableCell>
                                <TableCell>{t("neighborhood_access_rights")}</TableCell>
                                <TableCell>{t("neighborhood_admin_rights")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {localNeighborhoods.map((neighborhood, index) => (
                                <TableRow key={index} data-testid={`neighborhood-row-${index}`}>
                                    <TableCell data-testid={`neighborhood-id-${index}`}>
                                        <TextField
                                            value={neighborhood.name}
                                            onChange={(event) => handleNeighborhoodNameChange(event, neighborhood.neighborhoodId)}
                                            inputProps={{maxLength: MAX_NEIGHBORHOOD_NAME_LENGTH}}
                                            error={neighborhood.name?.trim() === ""}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox
                                            name={"isRestricted"} checked={neighborhood.restricted!}
                                            edge="end" data-testid={`neighborhood-restricted-${index}`}
                                            onChange={() => handleIsRestricted(neighborhood.neighborhoodId)}
                                        />
                                    </TableCell>
                                    <TableCell data-testid={`neighborhood-role-${index}`} style={tableCellRoleStyle}>
                                        <MultiSelectComboBox
                                            label={''}
                                            options={availableRoles.map((role: any) => role.roleName)}
                                            selectedValues={getRoleNames(neighborhood.roleIds)}
                                            onChange={selectedValues => handleRolesChange(selectedValues, neighborhood.neighborhoodId, 'access')}
                                            useDefaultStyles={false}
                                            disabled={!neighborhood.restricted}
                                        />
                                    </TableCell>
                                    <TableCell data-testid={`neighborhood-adminrole-${index}`}
                                               style={tableCellRoleStyle}>
                                        <MultiSelectComboBox
                                            label={''}
                                            options={availableRoles.map((role: any) => role.roleName)}
                                            selectedValues={getRoleNames(neighborhood.adminRoleIds)}
                                            onChange={selectedValues => handleRolesChange(selectedValues, neighborhood.neighborhoodId, 'admin')}
                                            useDefaultStyles={false}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions className={isNoFullscreen ? "dialogActionsMobile" : "dialogActions"}>
                    <CloseButton
                        style={{visibility: 'hidden', display: isNoFullscreen ? 'none' : 'flex'}}
                        size={isMobile ? 'small' : 'medium'}
                        data-testid={"close-btn-hidden"}
                    >
                    </CloseButton>
                    <div style={{flex: "1 1 auto"}}></div>
                    <Button onClick={checkChanges}
                            size={isMobile ? 'small' : 'medium'}
                            data-testid="neighborhood-save"
                            color="primary"
                            variant={"contained"}>
                        {t('button_save')}
                    </Button>
                    <div style={{flex: "1 1 auto"}}></div>
                    <CloseButton
                        size={isMobile ? 'small' : 'medium'}
                        onClick={handleClose}
                        data-testid={"close-btn"}
                    >
                    </CloseButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NeighborhoodManagerComponent;
