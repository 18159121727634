import {useMainApplicationContext} from "../useMainApplicationContext";
import {gql, useQuery} from "@apollo/client";
import {getSeatBookings} from "../../graphql/queries";
import {createNewTodayDateWithoutHours, toDateISO} from "../../services/DateUtils";
import {Booking} from "../../API";
import {useCallback} from "react";
import {DetailedSeatBooking} from "../../types/ExtendedSeatBookings";

export function useOwnCurrentSeatBookingsWithDetails() {
    const {rooms, currentUser} = useMainApplicationContext();
    const today: string = toDateISO(createNewTodayDateWithoutHours());
    const bookerId = currentUser.ID;
    const {
        refetch: refetchBookingsByBooker,
        loading: areBookingsLoading
    } = useQuery(gql(getSeatBookings), {
        variables: {
            input: {
                bookerId: bookerId,
                date: today,
                typeOfQuery: 'bookingsByBookerAndDate'
            },
            limit: 999
        }
    });

    const fetchSeatBookings = useCallback(async () : Promise<DetailedSeatBooking[]> => {
        const bookingsByBookerData = await refetchBookingsByBooker();
        return bookingsByBookerData.data.getSeatBookings.items.map(
            (b: Booking) => {
                const roomOfBooking = rooms.find(room => room.roomId === b.roomId);
                const roomName = roomOfBooking?.name ?? "";
                const isTimeActive = roomOfBooking?.isTimeActive ?? false;

                return (
                    {
                        ...b,
                        __typename: "Booking",
                        roomName: roomName,
                        isTimeActive: isTimeActive,
                    });
            }
        );
    }, [rooms, refetchBookingsByBooker]);

    return {areBookingsLoading: areBookingsLoading, fetchSeatBookings: fetchSeatBookings};
}