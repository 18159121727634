import {Button, ButtonProps} from "@material-ui/core";
import React from "react";

const PrimaryButton  = (props: ButtonProps) => {
    return (
        <Button color={"primary"} variant={"contained"} {...props}>
            {props.children}
        </Button>
    );
}
PrimaryButton.muiName = 'Button';

export default PrimaryButton;
