import {createContext} from "react";
import {roomPlanContextTypes} from "../types/RoomPlanContextTypes";

const RoomPlanContext = createContext<roomPlanContextTypes>({
    room: {
        __typename: "Room",
        roomId: "",
        name: "",
        nameLowerCased: "",
        createdAt: "",
        updatedAt: "",
    },
    currentUserID: "",
    itemsToDisplay: [],
    setItemsToDisplay: () => {
    },
    bookingsToDisplay: [],
    setBookingsToDisplay: () => {
    },
})

export default RoomPlanContext