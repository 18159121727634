import React, {createContext, useMemo, useState} from "react";
import {Booking} from "../../../API";


export interface ConfirmDeleteSeatBookingContextType {
    seatBookingToDelete: Booking | null,
    setSeatBookingToDelete: (value: Booking | null) => void,
    isSeatBookingDeletionConfirmationDialogOpen: boolean
    setIsSeatBookingDeletionConfirmationOpen: (value: boolean) => void
}

//Used for seat booking deletion confirmation dialog, that is opened from room plan view
export const ConfirmSeatBookingDeletionFromPlanContext = createContext<ConfirmDeleteSeatBookingContextType | null>(null);

export const ConfirmSeatBookingDeletionContextProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [bookingToDelete, setBookingToDelete] = useState<Booking | null>(null);
    const [isSeatBookingDeletionConfirmationOpen, setIsSeatBookingDeletionConfirmationOpen] = useState<boolean>(false);


    function setSeatBookingToDelete(value: Booking | null) {
        setBookingToDelete(value);
    }

    function setIsDialogOpen(value: boolean) {
        setIsSeatBookingDeletionConfirmationOpen(value);
    }

    const values : ConfirmDeleteSeatBookingContextType = useMemo(() => {
        return {
            seatBookingToDelete: bookingToDelete,
            setSeatBookingToDelete: setSeatBookingToDelete,
            isSeatBookingDeletionConfirmationDialogOpen: isSeatBookingDeletionConfirmationOpen,
            setIsSeatBookingDeletionConfirmationOpen: setIsDialogOpen
        }
    }, [bookingToDelete, isSeatBookingDeletionConfirmationOpen])

    return (
        <ConfirmSeatBookingDeletionFromPlanContext.Provider value={values}>
            {children}
        </ConfirmSeatBookingDeletionFromPlanContext.Provider>);
}