import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Edit from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import React, {useState} from "react";
import {t} from "i18next";
import {Building} from "../../API";
import {comparatorAlphanumericValues} from "../../Utils/Helpers";
import CreateBuildingComponent from "./CreateBuildingComponent";
import ManageBuildingComponent from "./ManageBuildingComponent";
import DeleteEntryConfirmationDialogComponent from "../DeleteEntryConfirmationDialogComponent";
import MuiAlert, {AlertProps} from "@material-ui/lab/Alert";
import Sit2GetherAddIcon from "../../styles/Sit2GetherAddIcon";
import Sit2GetherCloseIcon from "../../styles/Sit2GetherCloseIcon";
import {gql, useMutation} from "@apollo/client";
import {deleteSecureBuilding as deleteBuildingTemplate} from "../../graphql/mutations";
import ErrorBoundary from "../ErrorBoundary";
import {User} from "../../services/UserClient";
import {IOrgUnit} from "../../hooks/useOrgunit";
import DeleteButton from "../Buttons/DeleteButton";
import CloseButton from "../Buttons/CloseButton";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";

export interface BuildingProps {
    showBuildingManager: boolean
    setShowBuildingManager: (value: boolean) => void
    buildings: [] | Building[]
    currentUser: User
    orgUnitList: IOrgUnit[]
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BuildingManagerComponent: React.FC<BuildingProps> = (props) => {
    const {showBuildingManager, setShowBuildingManager, currentUser, buildings} = props

    const [selectedBuilding, setSelectedBuilding] = useState<Building | null>(null)
    const [isShowCreateBuilding, setIsShowCreateBuilding] = useState(false)
    const [isShowEditBuilding, setIsShowEditBuilding] = useState(false)
    const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false)
    const [isShowAlert, setIsShowAlert] = React.useState(false)
    const [deleteBuildingRequest] = useMutation(gql(deleteBuildingTemplate))
    const { isNoFullscreen, isMobile} = useDeviceMediaType()
    const handleCreateBuilding = () => {
        setIsShowCreateBuilding(true)
    }


    const sortBuildings = (buildings: Building[]) => {
        return buildings.sort((buildingOne: Building, buildingTwo: Building) => {
            return comparatorAlphanumericValues(buildingOne.buildingName, buildingTwo.buildingName)
        });
    }

    const deleteBuilding = async () => {
        await deleteBuildingRequest({
            variables: {
                input: {
                    buildingId: selectedBuilding!.buildingId,
                    buildingName: selectedBuilding?.buildingName,
                    buildingNameLowerCased: selectedBuilding?.buildingNameLowerCased,
                    roleIds: selectedBuilding?.roleIds
                }
            }
        })
        setSelectedBuilding(null)
        setIsShowDeleteDialog(false)

    }


    return (
        <Dialog style={{width: "100%"}} open={showBuildingManager} data-testid={"buildingManager"}>
            {isShowAlert && <Alert severity="error">{t("building-error-create-alert-title")}</Alert>}
            <DialogTitle>
                {t("building_mngmnt_dialog-title")}
            </DialogTitle>

            <ErrorBoundary>
                {(selectedBuilding !== null) && isShowEditBuilding && currentUser.isAdmin &&
                    <ManageBuildingComponent
                        key={selectedBuilding.buildingId}
                        showBuildingEditComponent={isShowEditBuilding}
                        setShowBuildingEditComponent={setIsShowEditBuilding}
                        selectedBuilding={selectedBuilding}
                        orgUnitList={props.orgUnitList}
                        currentUser={props.currentUser}
                    />}
            </ErrorBoundary>
                <DialogContent>

                    {isShowCreateBuilding &&
                        <CreateBuildingComponent
                            setShowCreateBuilding={setIsShowCreateBuilding}
                            setSelectedBuilding={setSelectedBuilding}
                            setIsShowAlert={setIsShowAlert}
                        />
                    }

                    {isShowDeleteDialog && <DeleteEntryConfirmationDialogComponent
                        isShowDeleteDialog={isShowDeleteDialog}
                        handleDelete={deleteBuilding}
                        setIsShowDeleteDialog={setIsShowDeleteDialog}
                    />

                    }

                    <TableContainer style={{maxHeight: "440px"}} data-testid="buildingManagerTable">
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell data-testid={"building-column"}>{t("building-column")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody data-testid={"select-building-list"}>
                                {sortBuildings(buildings).map((building: Building, index: number) => (
                                    <TableRow
                                        data-testid={`building-${index}`}
                                        key={building.buildingId} selected={selectedBuilding === building}
                                        onClick={() => setSelectedBuilding(building)}>
                                        <TableCell>
                                            {building.buildingName}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>

                <Divider/>

            <DialogActions className={isNoFullscreen ? "dialogActionsMobile" : "dialogActions"}>
                <CloseButton
                    style={{visibility: 'hidden', display: isNoFullscreen ? 'none' : 'flex'}}
                    data-testid={"btn-close-hidden"}
                ></CloseButton>
                <div style={{flex: "1 1 auto"}}></div>
                <Button variant={"contained"}
                        color={"primary"}
                        startIcon={!isMobile && <Sit2GetherAddIcon/>}
                        onClick={() => handleCreateBuilding()}
                        data-testid={"btn-create"}
                >
                    {t("building-create-button")}
                </Button>
                <Button variant={"contained"}
                        color={"primary"}
                        startIcon={!isMobile && <Edit/>}
                        disabled={!selectedBuilding}
                        onClick={() => setIsShowEditBuilding(true)}
                        data-testid={"btn-edit"}
                >
                    {t("building-manage-button")}
                </Button>
                <DeleteButton
                    disabled={!selectedBuilding}
                    size={isMobile ? 'small' : 'medium'}
                    onClick={() => setIsShowDeleteDialog(true)}
                    data-testid={"btn-delete"}>
                    {t('delete')}
                </DeleteButton>
                <div style={{flex: "1 1 auto"}}></div>
                <CloseButton
                    size={isMobile ? 'small' : 'medium'}
                    onClick={() => {
                        setShowBuildingManager(false);
                        setSelectedBuilding(null);
                    }}
                    data-testid={"btn-close"}
                ></CloseButton>
            </DialogActions>
        </Dialog>
    )
}

export default BuildingManagerComponent
