import React, {useCallback, useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Switch,
    TextField,
    Typography
} from '@material-ui/core';
import moment from "moment/moment";
import {gql, useMutation} from "@apollo/client";
import {useErrorContext} from "../hooks/useErrorContext";
import {createInfoText, updateInfoText} from "../graphql/mutations";
import {useTranslation} from "react-i18next";
import CloseButton from "./Buttons/CloseButton";
import {useDeviceMediaType} from "../hooks/useDeviceMediaType";

export interface InfoTextAdminDialogProps {
    showInfoTextDialog: boolean;
    setShowInfoTextDialog: (value: boolean) => void;
    infoText: string;
    isImportant: boolean;
}


export const InfoTextAdminDialog = (props: InfoTextAdminDialogProps) => {
    const INPUT_LENGTH = 500;
    const {showInfoTextDialog, setShowInfoTextDialog, infoText, isImportant} = props;
    const [textFieldValue, setTextFieldValue] = useState("");
    const [isInfoTextImportant, setIsInfoTextImportant] = useState(false);
    const [createInfoTextMutation] = useMutation(gql(createInfoText))
    const [updateInfoTextMutation] = useMutation(gql(updateInfoText))
    const {reportError} = useErrorContext();

    const {t} = useTranslation();

    useEffect(() => {
        setTextFieldValue(infoText)
        setIsInfoTextImportant(isImportant)
    }, [showInfoTextDialog]);


    const {isNoFullscreen, isMobile} = useDeviceMediaType()

    const createInfoDB = (updatedText: string, updatedImportance: boolean) => {
        createInfoTextMutation({
            variables: {
                input: {
                    id: "0",
                    updatedAt: moment().toISOString(),
                    createdAt: moment().toISOString(),
                    infoText: updatedText,
                    important: updatedImportance
                }
            }
        }).catch(err => reportError(err, "InfoTextDialog createInfoTextMutation"));
    }

    const updateInfoDB = (updatedText: string, updatedImportance: boolean) => {
        updateInfoTextMutation({
            variables: {
                input: {
                    id: "0",
                    updatedAt: moment().toISOString(),
                    infoText: updatedText,
                    important: updatedImportance
                }
            }
        }).catch(() => createInfoDB(updatedText, updatedImportance))
    }

    const handleInfoTextCancelEdit = () => {
        setShowInfoTextDialog(false);
    }

    const handleInfoTextSave = useCallback(() => {
        if (textFieldValue === "") {
            updateInfoDB("", false);
        } else {
            updateInfoDB(textFieldValue, isInfoTextImportant);
        }
        setShowInfoTextDialog(false);
    }, [textFieldValue, isInfoTextImportant])

    return (
        <Dialog open={showInfoTextDialog}>
            <DialogTitle>{t("user-info-text-header")}</DialogTitle>
            <DialogContent data-testid={"infoTextDialog"}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="infoTextContent"
                    type="text"
                    fullWidth
                    variant="outlined"
                    multiline
                    value={textFieldValue}
                    onChange={(e) => setTextFieldValue(e.target.value)}
                    inputProps={{maxLength: INPUT_LENGTH}}
                />
                <Typography>{`(${INPUT_LENGTH - textFieldValue.length}/${INPUT_LENGTH})`}</Typography>
                <FormControlLabel
                    label={t("user-info-text-importance")}
                    control={
                        <Switch
                            checked={isInfoTextImportant}
                            onChange={(e) => setIsInfoTextImportant(e.target.checked)}
                            color="primary"
                        />
                    }
                />
            </DialogContent>
            <DialogActions className={isNoFullscreen ? "dialogActionsMobile" : "dialogActions"}>
                <CloseButton
                    style={{visibility: 'hidden', display: isNoFullscreen ? 'none' : 'flex'}}
                    data-testid={"infotext-dialog-cancel-hidden"}>
                </CloseButton>
                <div style={{flex: "1 1 auto"}}></div>
                <Button key="save-button"
                        size={isMobile ? 'small' : 'medium'}
                        variant={"contained"} color={"primary"} component={"span"}
                        onClick={handleInfoTextSave}
                        data-testid={"infotext-dialog-save"}>
                    {t("save-button-text")}
                </Button>
                <div style={{flex: "1 1 auto"}}></div>
                <CloseButton
                    key="cancel-button"
                    size={isMobile ? 'small' : 'medium'}
                    onClick={handleInfoTextCancelEdit}
                    data-testid={"infotext-dialog-cancel"}>
                </CloseButton>
            </DialogActions>
        </Dialog>
    )
}
