import {Booking} from "../../API";
import dayjs, {Dayjs} from "dayjs";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";
import {TimeSlot, useFreeTimeWindows} from "../../hooks/bookings/useFreeTimeWindows";
import React, {useMemo} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import borders from "../../styles/borders";
import {useTranslation} from "react-i18next";
import TimeWindowTableRowComponent from "../MultiBookingDialog/TimeWindowTableRowComponent";

interface Props {
    bookings: Booking[],
    onTimeWindowClick: (start: Dayjs, end: Dayjs) => void
}

// Similar to TimeWindowTableComponent, but does not require Dates
export const SingleDayTimeWindowTableComponent: React.FC<Props> = (props) => {
    const {bookings, onTimeWindowClick} = props;
    const {isNoFullscreen} = useDeviceMediaType();
    const {t} = useTranslation();
    const bookingTimeSlots: TimeSlot[] = useMemo(() => {
        // TODO handle invalid / undefined / null timeBegin or timeEnd
        return bookings.map((b) => ({start: dayjs(b.timeBegin), end: dayjs(b.timeEnd)}));
    }, [bookings]);
    const freeTimeWindows = useFreeTimeWindows(bookingTimeSlots);

    return (
        <div style={{display: "flex", flexDirection: "row"}} data-testid={"time-window-table"}>
            <TableContainer sx={{
                minWidth: "300px",
                maxHeight: "400px",
                borderRadius: borders.mediumBorderRadius,
                overflow: "auto"
            }}>
                <Table stickyHeader>
                    {!isNoFullscreen &&
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}
                                           sx={{fontSize: "large"}}>
                                    {t("multibookingdialog_time_window_title")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    }
                    <TableBody data-testid={"time-window-table-body"}>
                        {
                            freeTimeWindows.map((timeWindow, index) => {
                                return <TimeWindowTableRowComponent timeWindow={timeWindow}
                                                                    onClick={onTimeWindowClick}
                                                                    key={index}/>
                            })
                        }
                        {freeTimeWindows.length == 0 &&
                            <TableRow>
                                <TableCell align={'center'} colSpan={2} width={1}
                                           data-testid={"time-window-table-no-common-time-slot"}>
                                    {t("multibookingdialog_time_window_no_common_time_slot")}
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>);
}