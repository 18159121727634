import {Button} from "@material-ui/core";
import React, {ChangeEvent} from "react";
import {Room} from "../../API";
import {useTranslation} from "react-i18next";

interface Props {
    handleUploadRoomPlan: (event: ChangeEvent<HTMLInputElement>) => void,
    selectedRoom: Room | undefined,
    uploadStartIcon: JSX.Element,
    smallButton: boolean | undefined
}

const RoomManagerUploadSvgButton: React.FC<Props> = (props) => {
    const {
        handleUploadRoomPlan,
        selectedRoom,
        uploadStartIcon,
        smallButton
    } = props
    const {t} = useTranslation();
    return (
        <>
            <input
                accept="image/*"
                style={{display: "none"}}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleUploadRoomPlan}
                disabled={!selectedRoom}
                onClick={(event) => {
                    const Target = event.target as HTMLInputElement
                    Target.value = ""
                }}
                data-testid={"upload-dialog"}
            />
            <label htmlFor="contained-button-file">
                <Button variant={"contained"} color={"primary"}
                        component={"span"} // TODO implizierte linksbündigkeit wegnehmen, aber ohne das funktioniert button nicht
                        size={smallButton ? "small" : "medium"}
                        startIcon={!smallButton && uploadStartIcon}
                        disabled={!selectedRoom}
                        data-testid={"btn-upload"}
                >
                    {t("button_upload-text")}
                </Button>
            </label>
        </>
    )
}

export default RoomManagerUploadSvgButton
