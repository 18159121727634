import {gql, useMutation} from "@apollo/client";
import {updateSecureSeatBooking} from "../../graphql/mutations";
import {UpdateSeatBookingInput} from "../../API";
import {useCallback} from "react";

export function useUpdateSeatBooking(){
    const [updateSeatBookingMutation] = useMutation(gql(updateSecureSeatBooking));

    return useCallback((input: UpdateSeatBookingInput) => {
        return updateSeatBookingMutation({
            variables: {
                input: {
                    ...input
                }
            }
        })
    }, [updateSeatBookingMutation]);
}

