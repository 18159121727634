import dayjs, {Dayjs} from "dayjs";
import {Booking, UpdateSeatBookingInput} from "../../API";
import {bookingForMaxLength} from "../../Utils/commons";
import {BookingFor} from "../../Utils/Enums";

export interface EditableData {
    beginTime: Dayjs | null,
    endTime: Dayjs | null,
    bookingFor: BookingFor,
    bookingForText: string
}

export interface EditableDataTimeNotNull {
    beginTime: Dayjs,
    endTime: Dayjs,
    bookingFor: BookingFor,
    bookingForText: string
}

export const defaultData: EditableData = {
    beginTime: null,
    endTime: null,
    bookingFor: BookingFor.BOOKING_FOR_ME,
    bookingForText: ""
}

// found in booking dialog
// TODO investigate if still required / which bug it is about
export function adjustDayjsMilliseconds(value: Dayjs) {
    // millisecond set to 0 because of possible bug in @mui/x-date-pickers
    return value.millisecond(0);
}

export function combineFormAndBookingData(dataFromForm: EditableDataTimeNotNull, uneditedBooking: Booking){
    const dataToSave: UpdateSeatBookingInput = {
        bookingId: uneditedBooking.bookingId,
        roomId: uneditedBooking.roomId,
        date: uneditedBooking.date,
        bookingFor: dataFromForm.bookingForText.trim(),
        timeBegin: dataFromForm.beginTime.toISOString(),
        timeEnd: dataFromForm.endTime.toISOString()
    };
    return dataToSave;
}

function isToday(date: Dayjs) {
    const today = dayjs();
    return date.day() === today.day() &&
        date.month() === today.month() &&
        date.year() === today.year();
}

function isTimeInPast(timeToCheck: Dayjs, dayOfTimeToCheck: string): boolean {
    const today = new Date();
    const day = new Date(dayOfTimeToCheck);
    day.setHours(timeToCheck.hour(), timeToCheck.minute(), 0, 0);
    return day.getTime() < today.getTime();
}

function endAfterStartTime(beginTime: Dayjs, endTime: Dayjs) {
    return endTime.isAfter(beginTime);
}

function isBookingOverlapping(
    beginTime: Dayjs,
    endTime: Dayjs,
    otherBookings: Booking[]
): boolean {
    return otherBookings.some((booking) => {
        const bookingStart = dayjs(booking.timeBegin);
        const bookingEnd = dayjs(booking.timeEnd);
        return beginTime.isBefore(bookingEnd) && endTime.isAfter(bookingStart);
    });
}

export function validateSeatBookingEditData(data: EditableData, bookingToEdit: Booking | null | undefined, otherBookings: Booking[]) {
    let isValid = true;
    let errorKeys: string[] = [];
    const {beginTime, endTime, bookingForText, bookingFor} = data;
    const beginTimeValid = beginTime?.isValid() ?? false;
    const endTimeValid = endTime?.isValid() ?? false;
    const bothTimesValid = beginTimeValid && endTimeValid;

    if (bookingToEdit === null || bookingToEdit === undefined) {
        return {isValid: false, errorKeys: ["sb-edit-no-booking-to-edit"]};
    }

    if (bookingToEdit.timeEnd === null || bookingToEdit.timeEnd === undefined) {
        return {isValid: false, errorKeys: ["sb-edit-booking-invalid"]};
    }

    const bookingToEditEndTimeDayjs = dayjs(bookingToEdit.timeEnd);
    if (!bookingToEditEndTimeDayjs.isValid()) {
        return {isValid: false, errorKeys: ["sb-edit-booking-invalid"]};
    }

    const bookingIsToday = isToday(bookingToEditEndTimeDayjs);
    if (bookingIsToday && endTimeValid) {
        const endTimeInPast = isTimeInPast(endTime as Dayjs, bookingToEdit.date);
        if (endTimeInPast) {
            isValid = false;
            errorKeys.push("sb-edit-today-end-time-in-past");
        }
    }

    if (bothTimesValid) {
        if (!endAfterStartTime(beginTime!, endTime!)) {
            isValid = false;
            errorKeys.push("sb-edit-order-start-end-time");
        }
        const isOverlapping = isBookingOverlapping(beginTime!, endTime!, otherBookings);
        if (isOverlapping) {
            isValid = false;
            errorKeys.push("sb-edit-bookings-overlap");
        }
    } else {
        isValid = false; // cant book but no error should be visible
    }

    if (bookingForText.length > bookingForMaxLength) {
        isValid = false;
        errorKeys.push("sb-edit-booking-for-name-too-long")
    }
    if (bookingFor === BookingFor.BOOKING_FOR_OTHERS && bookingForText.trim() === "") {
        isValid = false;
        errorKeys.push("sb-edit-missing-booking-for-name")
    }

    return {isValid: isValid, errorKeys: errorKeys};
}