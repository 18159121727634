import React, {useState} from "react";
import {Box, Tab, Tabs} from "@mui/material";
import {Booking} from "../../API";
import {BookingSettings} from "./BookingSettings";
import {useTranslation} from "react-i18next";
import {SingleDayTimeWindowTableComponent} from "../BookingDialog/SingleDayTimeWindowTableComponent";
import {Dayjs} from "dayjs";
import {columnStyleFixedWidth} from "../../Utils/commons";
import {EditableData} from "./seat-booking-edit-dialog-utils";

interface Props {
    otherBookings: Booking[],
    editableData: EditableData
    onDataChange: (newData: Partial<EditableData>) => void
    isTimeBookingActive: boolean
}

export const MobileDialogContent: React.FC<Props> = (props) => {
    const {
        otherBookings,
        editableData,
        onDataChange,
        isTimeBookingActive
    } = props;

    const {t} = useTranslation();

    const [currentTab, setCurrentTab] = useState(0);

    const handleMobileTabChange = (event: React.SyntheticEvent, newVal: number) => {
        setCurrentTab(newVal);
    }

    function handleClickOnTimeWindow(start: Dayjs, end: Dayjs) {
        onDataChange({beginTime: start, endTime: end});
    }

    return (
        <>
            <Box className={"dialogTab"} sx={{
                borderBottom: 1,
                borderColor: 'divider'
            }}>
                <Tabs value={currentTab} onChange={handleMobileTabChange}
                      variant={"scrollable"} scrollButtons={true} allowScrollButtonsMobile={true}>
                    <Tab label={t('multibookingdialog_meetingroom_settings_title')}/>
                    {isTimeBookingActive &&
                        <Tab label={t("multibookingdialog_time_window_title")}/>}
                </Tabs>
            </Box>
            <Box className={"dialogTab"}>
                {currentTab === 0 &&
                    <div style={{...columnStyleFixedWidth, gap: "16px"}}>
                        <BookingSettings data={editableData} onDataChange={onDataChange} timeActive={isTimeBookingActive}/>
                    </div>}
                {isTimeBookingActive && currentTab === 1 &&
                    <SingleDayTimeWindowTableComponent
                        bookings={otherBookings}
                        onTimeWindowClick={handleClickOnTimeWindow}/>}
            </Box>
        </>);
}