import React from "react";
import {Dialog, DialogActions, DialogContentText, DialogTitle} from "@material-ui/core";
import {DialogContent} from "@mui/material";
import {useTranslation} from "react-i18next";
import PrimaryButton from "../Buttons/PrimaryButton";
import DeleteButton from "../Buttons/DeleteButton";


interface Props {
    isOpen: boolean,
    onClose: () => void,
    onDelete: () => void,
    numberOfBookingsToDelete: number
}

export const OwnBookingsDeleteDialog: React.FC<Props> = (props) => {

    const {isOpen, onClose, onDelete, numberOfBookingsToDelete} = props;
    const {t} = useTranslation();

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth={"xs"} // TODO xs or md? for meeting room bookings "md" was used for seat booking "xs"
            data-testid={"own-bookings-alert-dialog"}
        >
            <DialogTitle>
                {t("own_bookings_management_alert_dialog_title")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("own_bookings_management_alert_dialog_amount_bookings")}: {numberOfBookingsToDelete}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <DeleteButton
                    disabled={numberOfBookingsToDelete === 0}
                    onClick={onDelete}
                    data-testid={"alert-dialog-btn-delete"}>
                    {t('delete')}
                </DeleteButton>
                <PrimaryButton
                    onClick={onClose}
                    data-testid={"alert-dialog-btn-close"}
                >
                    {t("confirm_dialog_cancel_button-text")}
                </PrimaryButton>
            </DialogActions>
        </Dialog>
    )
}
