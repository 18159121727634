import {ReportingFilteredData} from "./ReportingFilter";
import {SelectItem} from "../../Utils/MeetingRoomBookingSelectorsUtil";


export const optionsSorterByLabel = (a: SelectItem<string>, b: SelectItem<string>) => {
    return a.label.localeCompare(b.label);
};

export function createFilteredObject(object: any, date: string, selectedAssignedSeatsIndex: number = 0): ReportingFilteredData {
    // based on selectedAssignedSeatsIndex we count reserved seats some way
    return {
        date: date,
        bookedSeats: selectedAssignedSeatsIndex === 1 ? object?.reserved + object?.booked : object?.booked,
        bookableSeats: selectedAssignedSeatsIndex !== 0 ? object?.reserved + object?.bookable : object?.bookable
    }
}

export function arrayAttributeAddition(array: any[], attribure: string, addReserved: boolean = false, addAttributeName: string ): number {
    return array.reduce((counter, obj) => {
        const reserved = addReserved ? (obj[addAttributeName] as number) : 0
        counter += obj ? (obj[attribure] as number) + reserved : 0;
        return counter;
    }, 0);
}
