import React, {useEffect, useRef, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField} from "@material-ui/core";
import {t} from "i18next";
import Box from "@mui/material/Box";
import {Building, Room} from "../../API";
import ManageBuildingAssignedRoomsComponent from "./ManageBuildingAssignedRoomsComponent";
import ManageBuildingAvailableRoomsComponent from "./ManageBuildingAvailableRoomsComponent";
import OrgUnitInterfaceBuildingManager from "./OrgUnitInterfaceBuildingManager";
import {IOrgUnit} from "../../hooks/useOrgunit";
import {updateSecureBuilding as updateBuildingTemplate, updateSecureRoom} from "../../graphql/mutations";
import {User} from "../../services/UserClient";
import {useRoomList} from "../../hooks/useRoomList";
import {gql, useMutation, useQuery} from "@apollo/client";
import {listBuildings} from "../../graphql/queries";
import {checkForValidInputNaming, comparatorAlphanumericValues} from "../../Utils/Helpers";
import {useErrorContext} from "../../hooks/useErrorContext";
import Alert from "@material-ui/lab/Alert";
import {RoleSelectionComponent} from "./RoleSelectionComponent";
import {maxBuildingNameLength} from "../../Utils/commons";
import {Stack} from "@mui/material";
import CloseButton from "../Buttons/CloseButton";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";

const HANDLE_UPDATE_COMPLETE_ROOM = "updateCompleteRoom";

interface Props {
    showBuildingEditComponent: boolean;
    setShowBuildingEditComponent: (value: boolean) => void;
    selectedBuilding: Building;
    orgUnitList: IOrgUnit[];
    currentUser: User;
}

const ManageBuildingComponent: React.FC<Props> = (props) => {
    const {showBuildingEditComponent, setShowBuildingEditComponent, selectedBuilding} = props;
    const nameInputRef = useRef<HTMLInputElement>(null);
    const [newBuildingName, setNewBuildingName] = useState<string>("");
    const [seatConfEmail, setSeatConfEmail] = useState<string>(selectedBuilding?.seatConfEmail || "");
    const [assignedRooms, setAssignedRooms] = useState<Room[]>([]);
    const [selectedOrgUnit, setSelectedOrgUnit] = useState<IOrgUnit>(props.orgUnitList[0]);
    const [availableRooms, setAvailableRooms] = useState<Room[]>([]);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [roleIds, setRoleIds] = useState<string[]>(selectedBuilding.roleIds);

    const allRooms: Room[] = [...assignedRooms, ...availableRooms]
    const {rooms: allRoomsResult, roomsLoading} = useRoomList();

    const [updateBuildingRequest] = useMutation(gql(updateBuildingTemplate));
    const [updateRoomRequest] = useMutation((gql(updateSecureRoom)));

    const {reportError} = useErrorContext();
    const {refetch} = useQuery(gql(listBuildings));

    const updateSelectedOrgUnit = (orgUnit: IOrgUnit) => {
        setSelectedOrgUnit(orgUnit);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const input = event.target.value;
        setNewBuildingName(input);
        setShowAlert(false);
    };

    const handleConfEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const input = event.target.value;
        setSeatConfEmail(input);
        setShowAlert(false);
    };

    const showErrorMsg = () => {
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 4000);
    };

    const isConflictingBuildingName = async (): Promise<boolean> => {
        const queryBuildings = await refetch().catch((err) => reportError(err, "A connection error occurred", "ManageBuildingComponent"));
        if (queryBuildings === undefined) return true;
        const newBuildingNameLowerCase = newBuildingName.toLowerCase().trim();
        return queryBuildings!.data.listBuildings.items.some((building: Building) => {
            return comparatorAlphanumericValues(building.buildingName.toLowerCase().trim(), newBuildingNameLowerCase) === 0;
        });
    };

    const assignAndResetState = async () => {
        if (newBuildingName.trim() !== selectedBuilding.buildingName.trim() && await isConflictingBuildingName()) {
            setShowAlert(true);
            return;
        }

        updateBuilding().catch(() => showErrorMsg());
        updateRooms();
        setShowBuildingEditComponent(false);
        setShowAlert(false);
    };

    const updateBuilding = async () => {
        updateBuildingRequest({
            variables: {
                input: {
                    buildingId: selectedBuilding.buildingId,
                    buildingName: newBuildingName.trim(),
                    buildingNameLowerCased: newBuildingName.toLowerCase().trim(),
                    roleIds: roleIds,
                    seatConfEmail: seatConfEmail
                },
            },
        }).catch(() => {
            showErrorMsg();
        });
    };

    const updateRooms = () => {
        allRooms.forEach(room => {
            // @ts-ignore
            delete room!.createdAt
            // @ts-ignore
            delete room!.updatedAt
            // @ts-ignore
            delete room!.seats

            updateRoomRequest({
                variables: {
                    input: {
                        ...room
                    },
                    updateType: HANDLE_UPDATE_COMPLETE_ROOM
                }
            }).catch(error => {
                reportError(error, "A connection error occurred", "ManageBuildingComponent");
            })
        })
    }
    const closeWindow = () => {
        setShowBuildingEditComponent(false);
    };

    useEffect(() => {
        if (!roomsLoading) {
            setAssignedRooms(allRoomsResult.filter(room => room.buildingId === selectedBuilding.buildingId))
            setAvailableRooms(allRoomsResult.filter(room => room.buildingId === null));
            setNewBuildingName(selectedBuilding.buildingName)
        }
    }, [allRoomsResult]);

    const {isNoFullscreen, isMobile} = useDeviceMediaType()

    return (
        <Dialog fullWidth={true} maxWidth={"lg"} style={{width: "100%", height: "100%", flexGrow: 1}}
                open={showBuildingEditComponent} data-testid={"buildingManagerInEdit"}>

            {showAlert && <Alert severity="error"
                                 data-testid={"building-name-error"}>{t("building-error-create-alert-title")}</Alert>}
            <DialogTitle>{t("building-manage-title")}</DialogTitle>
            <DialogContent>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        justifyContent: "flex-start",
                        alignItems: "stretch",
                    }}
                >
                    <Box style={{paddingTop: "1rem", width: "100%"}}>
                        <TextField
                            style={{marginRight: "10px", width: "100%"}}
                            label={t("building-name-of-building")}
                            value={newBuildingName === undefined || newBuildingName.length === 0 ? selectedBuilding.buildingName : newBuildingName}
                            onChange={handleInputChange}
                            inputRef={nameInputRef}
                            inputProps={{"data-testid": "Building-Name-text"}}
                        />
                    </Box>

                    <Divider style={{marginBottom: "0rem"}}/>
                    <Box style={{paddingTop: "1rem", width: "100%"}}>
                        <ManageBuildingAssignedRoomsComponent
                            orgUnitList={props.orgUnitList}
                            assignedRooms={assignedRooms}
                            setAssignedRooms={setAssignedRooms}
                            availableRooms={availableRooms}
                            setAvailableRooms={setAvailableRooms}
                        />
                    </Box>
                    <Divider style={{marginBottom: "0rem"}}/>
                    <Box style={{paddingTop: "1rem", width: "100%", marginBottom: "-2.0rem"}}>
                        {<OrgUnitInterfaceBuildingManager
                            orgUnits={props.orgUnitList}
                            setSelectedOrgUnit={updateSelectedOrgUnit}
                            selectedOrgUnit={selectedOrgUnit}
                        />}
                    </Box>
                    <Box style={{paddingTop: "1rem", width: "100%"}}>
                        <ManageBuildingAvailableRoomsComponent
                            assignedRooms={assignedRooms}
                            setAssignedRooms={setAssignedRooms}
                            availableRooms={availableRooms}
                            setAvailableRooms={setAvailableRooms}
                            selectedOrgUnit={selectedOrgUnit}
                            selectedBuilding={selectedBuilding}
                            orgUnitList={props.orgUnitList}
                        />
                    </Box>
                    <Divider style={{marginBottom: "0rem"}}/>
                    <RoleSelectionComponent
                        roleIds={roleIds}
                        setRoleIds={setRoleIds}
                        label={"building-mange-role-selector"}
                        data-testid={"edit-building-roleIds-selector"}
                    ></RoleSelectionComponent>
                    <Divider/>
                </Stack>
            </DialogContent>

            <DialogActions className={isNoFullscreen ? "dialogActionsMobile" : "dialogActions"}>
                <CloseButton
                    style={{visibility: 'hidden'}}
                    data-testid={"manage-building-close-btn-hidden"}
                >
                </CloseButton>
                <div style={{flex: "1 1 auto"}}></div>
                <Button
                    size={isMobile ? "small" : "medium"}
                    onClick={() => assignAndResetState()}
                    color={"primary"}
                    variant={"contained"}
                    data-testid={"manage-building-save-btn"}
                    disabled={!checkForValidInputNaming(selectedBuilding.buildingName, maxBuildingNameLength)}
                >
                    {t("button_save")}
                </Button>
                <div style={{flex: "1 1 auto"}}></div>
                <CloseButton
                    size={isMobile ? "small" : "medium"}
                    onClick={() => {
                        closeWindow()
                    }}
                    data-testid={"manage-building-close-btn"}
                >
                </CloseButton>
            </DialogActions>
        </Dialog>
    );
};

export default ManageBuildingComponent; // Corrected export statement
