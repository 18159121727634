import React from "react";
import Alert from "@material-ui/lab/Alert";

interface AlertProps {
    showAlert: boolean,
    alertText: string
}

export const OptionalAlert: React.FC<AlertProps> = (props) => {
    const {showAlert, alertText} = props;
    if (showAlert) {
        return (
            <Alert severity="error" className={"alertStyle"}>
                {alertText}
            </Alert>
        );
    }
    return <></>;
}