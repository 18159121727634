import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import colors from "../../styles/colors";
import {BookingFor} from "../../Utils/Enums";
import {DropdownList} from "react-widgets/cjs";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    bookingFor: BookingFor
    onBookingForChange: (value: BookingFor) => void
}

const BOOKING_FOR_OPTIONS = [BookingFor.BOOKING_FOR_ME, BookingFor.BOOKING_FOR_OTHERS];

// TODO check styling
// TODO compare with MultiBookingDialogDropdown and check if one styling fits all use cases
export const BookingForSelector: React.FC<Props> = (props) => {
    const {bookingFor, onBookingForChange} = props;
    const {t} = useTranslation();
    return (
        <DropdownList
            dropUp
            data-testid={"edit-booking-for-dropdown"}
            data={BOOKING_FOR_OPTIONS}
            value={bookingFor}
            selectIcon={<KeyboardArrowDown fontSize={"small"} style={{color: colors.iconAndTextGrey}}/>}
            onSelect={val => onBookingForChange(val)}
            filter={false}
            renderListItem={({item}) => (
                <span>{(item === BookingFor.BOOKING_FOR_ME) ? t("multibookingdialog_dropdown_booking_for_me") : t("multibookingdialog_dropdown_booking_for_others")}</span>
            )}
            renderValue={({item}) => (
                <span>{(item === BookingFor.BOOKING_FOR_ME) ? t("multibookingdialog_dropdown_booking_for_me") : t("multibookingdialog_dropdown_booking_for_others")}</span>
            )}
        />);
}