import {Button, ButtonProps} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import DeleteIcon from "@material-ui/icons/Delete";

const DeleteButton = (props: ButtonProps) => {
    const {t} = useTranslation();
    return <Button color={"primary"}
                   variant={"contained"}
                   startIcon={props.size !== 'small' && <DeleteIcon/>}
                   {...props}>
        {props.children}
    </Button>;
}
DeleteButton.muiName = 'Button';

export default DeleteButton;
