import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {CustomIconButton} from "./CustomIconButton";
import {styled} from "@material-ui/core";
import {maternaTheme} from "../../../styles/materna_theme";
import {alpha} from '@mui/system';

export interface Props {
    displayText: string
    onEditButtonPressed: () => void,
    onDeleteButtonPressed: () => void,
}

const StyledEditCustomIconButton = styled(CustomIconButton)({
    marginRight: "4px",
    width: "30px",
});
const StyledDeleteCustomIconButton = styled(CustomIconButton)({
    width: "30px"
});


export const EditAndDeleteListItem: React.FC<Props> = ({displayText, onEditButtonPressed, onDeleteButtonPressed}) => {
    // with newer MUI version useTheme could be used to access secondary color
    const borderColor = alpha(maternaTheme.palette.secondary.main, 0.5);

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            sx={{
                border: "1px solid",
                borderRadius: "4px",
                padding: "4px",
                boxSizing: "border-box",
                borderColor: borderColor,
            }}
        >
            <Typography
                variant="caption"
                sx={{
                    fontWeight: "bold",
                    marginRight: "10px",
                    fontSize: "13px"
                }}
            >
                {displayText}
            </Typography>
            <Box>
                <StyledEditCustomIconButton
                    icon={<EditIcon/>}
                    onClick={onEditButtonPressed}
                />
                <StyledDeleteCustomIconButton icon={<DeleteIcon/>} onClick={onDeleteButtonPressed}/>
            </Box>
        </Stack>
    );
}

