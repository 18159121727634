import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import React, {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {DialogButton} from "../Buttons/DialogButton";


interface Props {
    isOpen: boolean
    title?: string
    children?: ReactNode
    onCancel: () => void
    onConfirm: () => void
}

export const ConfirmationDialog: React.FC<Props> = (props) => {
    const {isOpen, title, onCancel, onConfirm, children} = props;
    const {t} = useTranslation();

    return (
        <Dialog open={isOpen}>
            {title !== undefined &&
                <DialogTitle>
                    {title}
                </DialogTitle>
            }
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions style={{display: "flex", justifyContent: "center"}}>
                <DialogButton onClick={onCancel}>
                    {t("confirm_dialog_cancel_button-text")}
                </DialogButton>
                <DialogButton onClick={onConfirm}>
                    {t("confirm_dialog_ok_button-text")}
                </DialogButton>
            </DialogActions>
        </Dialog>
    );
}