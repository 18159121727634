import {useCallback, useState} from "react";
import {DetailedSeatBooking} from "../../types/ExtendedSeatBookings";
import {Booking} from "../../API";
import {useDeleteSeatBooking} from "./useDeleteSeatBooking";
import {useOwnCurrentSeatBookingsWithRoomNames} from "./useOwnCurrentSeatBookingsWithRoomNames";
import {useOwnCurrentSeatBookingsWithDetails} from "./useOwnCurrentSeatBookingsWithDetails";
import {useHandleUnknownErrors} from "../errorHandling/useHandleUnknownErrors";
import {useTranslation} from "react-i18next";

function getUpdatedSelection(oldSelection: DetailedSeatBooking[], updatedBookings: DetailedSeatBooking[]){
    return  oldSelection.reduce((acc: DetailedSeatBooking[], current) => {
        const newBooking = updatedBookings.find((b) => b.bookingId === current.bookingId);
        if (newBooking === undefined){
            return acc;
        }
        return [...acc, newBooking]
    }, []);
}

// For handling seat bookings in OwnBookingsManagerComponent
// Fetch and delete own seat bookings, store selection
export function useOwnDetailedSeatBookings() {
    const [seatBookings, setSeatBookings] = useState<DetailedSeatBooking[]>([]);
    const [selectedSeatBookings, setSelectedSeatBookings] = useState<DetailedSeatBooking[]>([]);
    const deleteSeatBooking = useDeleteSeatBooking();
    const {areBookingsLoading, fetchSeatBookings} = useOwnCurrentSeatBookingsWithDetails();
    const {t} = useTranslation();

    const reportAnyError = useHandleUnknownErrors();

    const onSelectSeatBookings = useCallback((newSelection: DetailedSeatBooking[]) => {
        setSelectedSeatBookings(newSelection);
    }, []);

    const refetchSeatBookings = useCallback(async () => {
        return fetchSeatBookings()
            .then(
                bookings => {
                    setSelectedSeatBookings(prev => getUpdatedSelection(prev, bookings));
                    setSeatBookings(bookings);
                }
            )
            .catch(
                (err) => reportAnyError(err, t("error_loading_bookings"), "OwnSeatBookingsTabComponent fetchSeatBookingsAndUpdateState"));
    }, [fetchSeatBookings, setSeatBookings, reportAnyError, t]);

    const deleteSelectedBookings = useCallback(async () => {
        const promises = selectedSeatBookings.map(
            (booking) => deleteSeatBooking(booking)
        );
        setSelectedSeatBookings([]);
        return Promise.all(promises)
            .catch((err) => {
                reportAnyError(err, t("error_deleting_bookings"), "useOwnDetailedSeatBookings deleteSelectedBookings")
            }).finally(() => refetchSeatBookings())
    }, [deleteSeatBooking, setSelectedSeatBookings, reportAnyError, selectedSeatBookings, refetchSeatBookings, t]);

    return {
        areSeatBookingsLoading: areBookingsLoading,
        detailedSeatBookings: seatBookings,
        selectedSeatBookings: selectedSeatBookings,
        onSelectSeatBookings: onSelectSeatBookings,
        refetchSeatBookings: refetchSeatBookings,
        deleteSelectedSeatBookingsAndRefetch: deleteSelectedBookings,
    };
}