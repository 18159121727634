import React from "react";
import {Dayjs} from "dayjs";
import {BookingForSelector} from "../SeatBooking/BookingForSelector";
import {BookingFor} from "../../Utils/Enums";
import MultiBookingDialogNameField from "../MultiBookingDialog/MultiBookingDialogNameField";
import {EditableData} from "./seat-booking-edit-dialog-utils";
import DoubleTimePicker from "../MultiBookingDialog/DoubleTimePicker";

interface Props {
    data: EditableData,
    onDataChange: (newData: Partial<EditableData>) => void,
    timeActive: boolean
}

export const BookingSettings: React.FC<Props> = (props) => {
    const {data, onDataChange, timeActive} = props;
    const {bookingFor, endTime, beginTime, bookingForText} = data;

    function handleBeginTimeChanged(newValue: Dayjs | null) {
        onDataChange({beginTime: newValue});
    }

    function handleEndTimeChanged(newValue: Dayjs | null) {
        onDataChange({endTime: newValue});
    }

    function handleBookingForChange(value: BookingFor) {
        onDataChange({bookingFor: value});
    }

    function handleBookingForNameChange(value: string) {
        onDataChange({bookingForText: value});
    }

    return (
        <>
            {timeActive ?
                <div className={"leftSideChild"} style={{display: "flex", marginTop: "10px"}}>
                    <DoubleTimePicker
                        startTime={beginTime}
                        onChangeStartTime={handleBeginTimeChanged}
                        endTime={endTime}
                        onChangeEndTime={handleEndTimeChanged}/>
                </div>
                :
                <div style={{height: "80px"}}></div> // placeholder for selector popup
            }
            <BookingForSelector bookingFor={bookingFor} onBookingForChange={handleBookingForChange}/>
            {bookingFor === BookingFor.BOOKING_FOR_OTHERS &&
                    <MultiBookingDialogNameField bookingForInputUsername={bookingForText}
                                                 setBookingForInputUsername={handleBookingForNameChange}/>
            }
        </>
    );
}