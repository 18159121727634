import React, {useCallback, useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select
} from "@material-ui/core";
import {
    Building,
    CreateDefaultRoomConfigInput,
    CreateMailConfigInput,
    CreateUserSettingsInput,
    DefaultRoomConfig,
    MailConfig,
    Neighborhood,
    Room
} from "../API";
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    createDefaultRoomConfig,
    createMailConfig,
    deleteSecureSeatBookingsByUserId,
    updateDefaultRoomConfig,
    updateMailConfig
} from "../graphql/mutations";
import {useMainApplicationContext} from "../hooks/useMainApplicationContext";
import {useErrorContext} from "../hooks/useErrorContext";
import {useTranslation} from "react-i18next";
import Divider from "@material-ui/core/Divider";
import uiElementMeasures from "../styles/inputElementMeasures";
import {useNeighborhoodList} from "../hooks/useNeighborhoodList";
import Alert from "@material-ui/lab/Alert";
import Box from '@mui/material/Box';
import BuildingSelectorDropdown from "./SelectorComponents/BuildingSelectorDropdown";
import RoomSelectorDropdown from "./SelectorComponents/RoomSelectorDropdown";
import NeighborhoodSelectorDropdown from "./SelectorComponents/NeighborhoodSelectorDropdown";
import {useDeviceMediaType} from "../hooks/useDeviceMediaType";
import ConfirmationDialog from "./ConfirmationDialog";
import {getSeatBookings} from "../graphql/queries";
import {createSecureUserSettings, updateSecureUserSettings} from "../graphqlCustom/mutationsCustom";
import CloseButton from "./Buttons/CloseButton";


export interface SettingsProps {
    showMySettingsDialog: boolean
    setShowMySettingsDialog: (value: boolean) => void
}

const MySettingsComponent: React.FC<SettingsProps> = (props) => {
    const {showMySettingsDialog, setShowMySettingsDialog} = props
    const {currentUser, userSettingsObject, buildingList, rooms, isLoading} = useMainApplicationContext();
    const {reportError} = useErrorContext();

    const userSettingsFromDB = userSettingsObject?.userSettings;
    const setShouldRefetchUserSettings = userSettingsObject?.setShouldRefetchUserSettings;
    const mailConfigFromDB = userSettingsFromDB?.mailConfig;
    const roomPlanConfigFromDB = userSettingsFromDB?.defaultRoomConfig;

    const [createMailConfigMutation] = useMutation(gql(createMailConfig))
    const [updateMailConfigMutation] = useMutation(gql(updateMailConfig))

    const [createDefaultRoomConfigMutation] = useMutation(gql(createDefaultRoomConfig))
    const [updateDefaultRoomConfigMutation] = useMutation(gql(updateDefaultRoomConfig))

    const [createSecureUserSettingsMutation] = useMutation(gql(createSecureUserSettings))
    const [updateSecureUserSettingsMutation] = useMutation(gql(updateSecureUserSettings))

    const [deleteSeatBookingsByUserMutation] = useMutation(gql(deleteSecureSeatBookingsByUserId))

    const [userMailConfig, setUserMailConfig] = useState<MailConfig | undefined>(undefined)
    const [isReceiveEmailsForOwnBookings, setIsReceiveEmailsForOwnBookings] = useState<boolean>(false)
    const [isReceiveEmailsForForeignBookings, setIsReceiveEmailsForForeignBookings] = useState<boolean>(false)
    const [isReceiveEmailsForMeetingRoomBookings, setIsReceiveEmailsForMeetingRoomBookings] = useState<boolean>(false)

    const [userRoomConfig, setUserRoomConfig] = useState<DefaultRoomConfig | undefined>(undefined);
    const [selectedBuilding, setSelectedBuilding] = useState<Building | undefined>(undefined)
    const [selectedRoomPlan, setSelectedRoomPlan] = useState<Room | undefined>(undefined);
    const [selectedNeighborhood, setSelectedNeighborhood] = useState<Neighborhood | undefined>(undefined);

    const [areOptionsFilledFromDefault, setAreOptionsFilledFromDefault] = useState(false);
    const [isDefaultRoomConfigInvalid, setIsDefaultRoomConfigInvalid] = useState(false);

    // State for roomplan data
    const [accessibleBuildings, setAccessibleBuildings] = useState<Building[]>([]);
    const [areAccessibleBuildingsFound, setAreAccessibleBuildingsFound] = useState(false);

    const [validRooms, setAccessibleRooms] = useState<Room[]>([]);
    const [areValidRoomsFound, setAreAccessibleRoomsFound] = useState(false);

    const [neighborhoods] = useNeighborhoodList(selectedRoomPlan?.roomId, false)
    const [consent, setConsent] = React.useState<boolean>(true);

    const {t} = useTranslation();

    let tmpSavedNeighborhood: Neighborhood | undefined = undefined;

    useEffect(() => {
        setConsent(!!userSettingsObject?.userSettings?.consent);
    }, [userSettingsObject?.userSettings?.consent]);

    useEffect(function populateAccessibleRoomsAndBuildings() {

        const accessibleRooms = getAccessibleRooms();
        setAccessibleRooms(accessibleRooms);
        setAreAccessibleRoomsFound(accessibleRooms.length > 0);

        const accessibleBuildings = getAccessibleBuildings();
        setAccessibleBuildings(accessibleBuildings);
        setAreAccessibleBuildingsFound(accessibleBuildings.length > 0);

        if (selectedRoomPlan) setSelectedBuilding(buildingList.find((building) => building.buildingId === selectedRoomPlan.buildingId!));

        function getAccessibleRooms() {
            return rooms.filter(isUserAllowedToSeeRoom).sort();

            function isUserAllowedToSeeRoom(room: Room) {
                const isRoomValid = Boolean(room.isActive) && Boolean(room.buildingId) && Boolean(room.orgUnitId);
                if (!isRoomValid) return false;

                const isRoomInOrgUnitOfUser = Boolean(currentUser.orgUnits?.find(orgunit => orgunit.orgId === room.orgUnitId))

                return Boolean(currentUser?.isAdmin) || Boolean(room?.isPublic) || isRoomInOrgUnitOfUser;
            }
        }

        function getAccessibleBuildings() {
            return buildingList
                .filter(building => accessibleRooms.some(room => room.buildingId === building.buildingId))
                .sort();
        }

    }, [currentUser, buildingList, rooms, selectedRoomPlan]);

    useEffect(function populateNeighborhoodSelectorOnRoomChange() {
        if (!selectedNeighborhood && neighborhoods.length > 0 && selectedRoomPlan) {
            if (userRoomConfig?.neighborhoodId) handleOnNeighborhoodChanged(neighborhoods.find(neighborhood => neighborhood.neighborhoodId === userRoomConfig.neighborhoodId)?.neighborhoodId);
            else if (tmpSavedNeighborhood) return;
            else if (areOptionsFilledFromDefault) handleOnNeighborhoodChanged(getFirstNeighborhoodInRoom(selectedRoomPlan)?.neighborhoodId);
        }
    }, [neighborhoods]);

    useEffect(function populateMailConfigAndRoomConfig() {
        if (!showMySettingsDialog) return;
        populateMailConfig()
        populateRoomConfig();

        function populateMailConfig() {
            if (mailConfigFromDB && !userMailConfig) {
                setUserMailConfig(mailConfigFromDB)
                setIsReceiveEmailsForOwnBookings(mailConfigFromDB?.isReceiveOwnBooking ?? false)
                setIsReceiveEmailsForForeignBookings(mailConfigFromDB?.isReceiveForeignBooking ?? false)
                setIsReceiveEmailsForMeetingRoomBookings(mailConfigFromDB?.isReceiveMeetingRoomBooking ?? false)
            }
        }

        function populateRoomConfig() {
            const isRoomPlanConfigNotInitialised = (!roomPlanConfigFromDB && isLoading) || areOptionsFilledFromDefault;
            if (isRoomPlanConfigNotInitialised) return;

            setUserRoomConfig(roomPlanConfigFromDB!);
            const isRoomPlanInitialisedButEmpty = !roomPlanConfigFromDB?.roomId;
            if (isRoomPlanInitialisedButEmpty) {
                signalThatDefaultRoomConfigIsUsed();
                handleOnBuildingChanged(accessibleBuildings[0].buildingId)
                return;
            }
            if (!areValidRoomsFound || !areAccessibleBuildingsFound) return;
            const defaultRoom = getDefaultRoom();
            if (!defaultRoom) {
                signalThatDefaultRoomIsInvalid();
                return;
            }

            populateSelectedRoomAndBuilding();
            populateNeighborhood();

            function signalThatDefaultRoomConfigIsUsed() {
                setAreOptionsFilledFromDefault(true);
            }

            function getDefaultRoom() {
                return validRooms.find(room => room.roomId === roomPlanConfigFromDB?.roomId);
            }

            function signalThatDefaultRoomIsInvalid() {
                setAreOptionsFilledFromDefault(true);
                setIsDefaultRoomConfigInvalid(true);
                handleOnRoomPlanDefaultChanged();
            }

            function populateSelectedRoomAndBuilding() {
                if (!selectedBuilding) {
                    setSelectedBuilding(accessibleBuildings[0]);
                }
                if (!selectedRoomPlan) {
                    setSelectedRoomPlan(defaultRoom);
                    if (defaultRoom) setSelectedBuilding(buildingList.find(building => building.buildingId === defaultRoom.buildingId));
                }
            }

            function populateNeighborhood() {
                if (defaultRoom?.hasNeighborhood && neighborhoods.length > 0) {
                    const neighborhood = neighborhoods.find(hood => roomPlanConfigFromDB?.neighborhoodId === hood.neighborhoodId);
                    tmpSavedNeighborhood = neighborhood;
                    setSelectedNeighborhood(neighborhood);
                    setAreOptionsFilledFromDefault(true);

                } else if (!defaultRoom?.hasNeighborhood) {
                    setAreOptionsFilledFromDefault(true);
                }
            }

        }
    }, [userSettingsFromDB, currentUser, neighborhoods, areValidRoomsFound, areAccessibleBuildingsFound, showMySettingsDialog])

    /*
    HANDLER FUNCTIONS ROOMPLAN
     */
    function handleOnRoomPlanDefaultChanged() {
        if (!hasAccessibleRoomPlans() || !areValidRoomsFound || !areAccessibleBuildingsFound) return;
        handleOnBuildingChanged(accessibleBuildings[0]?.buildingId);
    }

    function handleOnBuildingChanged(buildingID: string) {
        const selection = buildingList.find(building => building.buildingId === buildingID);
        if (!selection) return;
        if (selection !== selectedBuilding) {
            setSelectedBuilding(selection);
            handleOnRoomPlanChanged(getFirstRoomInBuilding(selection)?.roomId);
        }
    }

    function handleOnRoomPlanChanged(roomId: string | undefined) {
        const selection = validRooms.find(room => room.roomId === roomId);
        if (!selection) return;
        if (selection !== selectedRoomPlan) {
            setSelectedRoomPlan(selection)
            if (selection.hasNeighborhood) setSelectedNeighborhood(getFirstNeighborhoodInRoom(selection));
            else setSelectedNeighborhood(undefined);
        }
    }

    function handleOnNeighborhoodChanged(neighborhoodId: string | undefined) {
        const selection = neighborhoods.find(neighborhood => neighborhood.neighborhoodId === neighborhoodId);
        if (selection !== selectedNeighborhood) {
            setSelectedNeighborhood(selection)
        }
    }

    const saveConfigs = useCallback(() => {
        const userSettingInput: CreateUserSettingsInput = {
            userId: currentUser.ID,
            userSettingsMailConfigId: currentUser.ID,
            consent: consent,
            userSettingsDefaultRoomConfigId: currentUser.ID
        }
        const mailConfigInput: CreateMailConfigInput = {
            userId: currentUser.ID,
            userMail: currentUser.email,
            isReceiveOwnBooking: isReceiveEmailsForOwnBookings,
            isReceiveForeignBooking: isReceiveEmailsForForeignBookings,
            isReceiveMeetingRoomBooking: isReceiveEmailsForMeetingRoomBookings,
        }
        const defaultRoomConfigInput: CreateDefaultRoomConfigInput = {
            userId: currentUser.ID,
            roomId: selectedRoomPlan?.roomId ?? "",
            roomName: selectedRoomPlan?.name ?? "",
            neighborhoodId: selectedNeighborhood?.neighborhoodId ?? "",
        }

        if (userSettingsFromDB) {
            updateSecureUserSettingsMutation({variables: {input: userSettingInput}})
                .catch(err => reportError(err, "", "MySettingsComponent updateUserSettingsMutation"));
        } else {
            createSecureUserSettingsMutation({variables: {input: userSettingInput}})
                .catch(error => reportError(error, "", "MySettingsComponent createUserSettingsMutation"));
        }
        if (userSettingsFromDB?.mailConfig) {
            updateMailConfigMutation({variables: {input: mailConfigInput}})
                .catch(error => reportError(error, "", "MySettingsComponent updateMailConfigMutation"));
        } else {
            createMailConfigMutation({variables: {input: mailConfigInput}})
                .catch(error => reportError(error, "", "MySettingsComponent createMailConfigMutation"));
        }
        if (userSettingsFromDB?.defaultRoomConfig) {
            updateDefaultRoomConfigMutation({variables: {input: defaultRoomConfigInput}})
                .then(() => setShowMySettingsDialog(false))
                .catch(error => reportError(error, "", "MySettingsComponent updateDefaultRoomConfigMutation"))
                .finally(() => refetchUserSettings())
        } else {
            createDefaultRoomConfigMutation({variables: {input: defaultRoomConfigInput}})
                .then(() => setShowMySettingsDialog(false))
                .catch(error => reportError(error, "", "MySettingsComponent createDefaultRoomConfigMutation"))
                .finally(() => refetchUserSettings())
        }

        function refetchUserSettings() {
            if (!!setShouldRefetchUserSettings) {
                setShouldRefetchUserSettings(prev => !prev);
            }
        }

    }, [consent, currentUser.ID, currentUser.email, isReceiveEmailsForOwnBookings, isReceiveEmailsForForeignBookings, isReceiveEmailsForMeetingRoomBookings, selectedRoomPlan?.roomId, selectedRoomPlan?.name, selectedNeighborhood?.neighborhoodId, userSettingsFromDB, deleteSeatBookingsByUserMutation, reportError, updateSecureUserSettingsMutation, createSecureUserSettingsMutation, updateMailConfigMutation, createMailConfigMutation, updateDefaultRoomConfigMutation, setShowMySettingsDialog, createDefaultRoomConfigMutation, setShouldRefetchUserSettings])

    const handleCloseMySettingsDialog = useCallback(() => {
        setConsent(!!userSettingsObject?.userSettings?.consent);
        setShowMySettingsDialog(false)
        setIsReceiveEmailsForOwnBookings(userMailConfig?.isReceiveOwnBooking ?? false)
        setIsReceiveEmailsForForeignBookings(userMailConfig?.isReceiveForeignBooking ?? false)
        setIsReceiveEmailsForMeetingRoomBookings(userMailConfig?.isReceiveForeignBooking ?? false)
        setAreOptionsFilledFromDefault(false);
        setIsDefaultRoomConfigInvalid(false);
        setSelectedBuilding(undefined);
        setSelectedRoomPlan(undefined);
        setSelectedNeighborhood(undefined);
    }, [setShowMySettingsDialog, userMailConfig, userSettingsObject?.userSettings?.consent])

    const handleReceiveEmailsForOwnBookingsClick = useCallback(() => setIsReceiveEmailsForOwnBookings((oldValue) => !oldValue), []);

    const handleReceiveEmailsForForeignBookingsClick = useCallback(() => setIsReceiveEmailsForForeignBookings((oldValue) => !oldValue), []);

    const handleReceiveEmailsForMeetingRoomBookingsClick =
        useCallback(() => setIsReceiveEmailsForMeetingRoomBookings((oldValue) => !oldValue), []);

    /*
    HELPER FUNCTIONS
     */
    function hasAccessibleRoomPlans() {
        return (validRooms.length > 0 && buildingList.length > 0 && buildingList.find(building => building.buildingId === validRooms[0].buildingId))
    }

    const getFirstRoomInBuilding = (building: Building): Room | undefined => validRooms.find(room => room.buildingId === building.buildingId)

    function getFirstNeighborhoodInRoom(room: Room): Neighborhood | undefined {
        return undefined;
    }

    function handleOnSave() {
        saveConfigs();
    }

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const {isNoFullscreen, isMobile} = useDeviceMediaType()

    return (
        <>
            <ConfirmationDialog isOpen={showConfirmationDialog}
                                title={t('my_settings-no_consent_deletion_title')}
                                setIsOpen={setShowConfirmationDialog}
                                onCancel={() => setShowConfirmationDialog(false)}
                                onOk={() => {
                                    saveConfigs();
                                    setShowConfirmationDialog(false);
                                }}>
                <div style={{marginBottom: '12px'}}>
                    {t('my_settings-no_consent_deletion_text')}
                </div>
            </ConfirmationDialog>
            <Dialog open={showMySettingsDialog}>
                <DialogTitle>{t("my_settings-dialog-title")}</DialogTitle>
                <DialogContent>
                    <h5>{t("notification-dialog-title")}</h5>
                    <p>{t("notification-dialog-hint")}</p>
                    <List style={{maxHeight: 300, overflow: 'auto'}}>
                        <ListItem ContainerComponent="div" disableRipple key={1} dense button
                                  onClick={handleReceiveEmailsForOwnBookingsClick}>
                            <ListItemText>{t("notification-option-1")}</ListItemText>
                            <Checkbox data-testid={"notification-checkbox-1"}
                                      edge="end"
                                      checked={isReceiveEmailsForOwnBookings}
                            />
                        </ListItem>
                        <ListItem ContainerComponent="div" disableRipple key={2} dense button
                                  onClick={handleReceiveEmailsForForeignBookingsClick}>
                            <ListItemText>{t("notification-option-2")}</ListItemText>
                            <Checkbox data-testid={"notification-checkbox-2"}
                                      edge="end"
                                      checked={isReceiveEmailsForForeignBookings}
                            />
                        </ListItem>
                        <ListItem ContainerComponent="div" disableRipple key={3} dense button
                                  onClick={handleReceiveEmailsForMeetingRoomBookingsClick}>
                            <ListItemText>{t("notification-option-3")}</ListItemText>
                            <Checkbox data-testid={"notification-checkbox-3"}
                                      edge="end"
                                      checked={isReceiveEmailsForMeetingRoomBookings}
                            />
                        </ListItem>
                    </List>

                    <Divider></Divider>

                    <h5>{t("my_settings-room_plan-title")}</h5>
                    {isDefaultRoomConfigInvalid && userRoomConfig &&
                        <Alert severity="warning">{t("my_settings-default-warning-1")}
                            {` (${t("general_room-singular")}: ${userRoomConfig.roomName ?? userRoomConfig.roomId}${userRoomConfig?.neighborhoodId ? "/" + t("general_neighborhood-singular") + ": " + userRoomConfig?.neighborhoodId : ""}) `}
                            {t("my_settings-default-warning-2")}</Alert>}
                    {hasAccessibleRoomPlans() &&
                        <div>
                            <Box style={{marginBottom: uiElementMeasures.marginBetweenElementsInColumn}}>
                                <BuildingSelectorDropdown
                                    buildings={accessibleBuildings}
                                    selectedBuildingId={selectedBuilding?.buildingId}
                                    onBuildingSelect={handleOnBuildingChanged}
                                />
                            </Box>
                            <Box style={{marginBottom: uiElementMeasures.marginBetweenElementsInColumn}}>
                                <RoomSelectorDropdown
                                    rooms={validRooms}
                                    selectedRoomId={selectedRoomPlan?.roomId}
                                    onRoomSelect={handleOnRoomPlanChanged}
                                    selectedBuildingId={selectedBuilding?.buildingId}
                                />
                            </Box>

                            {selectedRoomPlan && selectedRoomPlan.hasNeighborhood && (
                                <Box style={{marginBottom: uiElementMeasures.marginBetweenElementsInColumn}}>
                                    <NeighborhoodSelectorDropdown
                                        neighborhoods={neighborhoods}
                                        selectedNeighborhoodId={selectedNeighborhood?.neighborhoodId}
                                        selectedRoomId={selectedRoomPlan.roomId}
                                        onNeighborhoodSelect={handleOnNeighborhoodChanged}
                                    />
                                </Box>
                            )}
                        </div>}

                    <Divider></Divider>

                    <h5>{t("my_settings-consent_title")}</h5>
                    <Box>
                        <p>{t("my_settings-hint_text")}</p>
                        <FormControl fullWidth>
                            <Select
                                value={"" + consent}
                                onChange={(event) => {
                                    setConsent(event.target.value === "true")
                                }}>
                                <MenuItem value={"true"}>{t("my_settings-consent_text")}</MenuItem>
                                <MenuItem value={"false"}>{t("my_settings-no_consent_text")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions className={isNoFullscreen ? "dialogActionsMobile" : "dialogActions"}>
                    <CloseButton
                        style={{visibility: 'hidden', display: isNoFullscreen ? 'none' : 'flex'}}
                        data-testid={"my-settings-dialog-btn-close-hidden"}
                    ></CloseButton>
                    <div style={{flex: "1 1 auto"}}></div>
                    <Button
                        size={isMobile ? 'small' : 'medium'}
                        onClick={handleOnSave}
                        color={"primary"}
                        variant={"contained"}
                        data-testid={"my-settings-dialog-btn-save"}
                    >
                        {t("button_save")}
                    </Button>
                    <div style={{flex: "1 1 auto"}}></div>
                    <CloseButton
                        size={isMobile ? 'small' : 'medium'}
                        onClick={handleCloseMySettingsDialog}
                        data-testid={"my-settings-dialog-btn-close"}
                    ></CloseButton>
                </DialogActions>
            </Dialog>
        </>)
}

export default MySettingsComponent
