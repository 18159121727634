import {gql, useQuery} from "@apollo/client";
import {getMeetingRoomBookings, getSeatBookings} from "../graphql/queries";
import {useEffect, useMemo} from "react";
import {Booking, MeetingRoomBooking} from "../API"
import {SUBSCRIPTION_DELAY} from "../Utils/commons";
import {
    onChangeMeetingRoomBooking,
    onChangeSeatBooking,
    onUpdateRestrictedNeighborhoods,
} from "../graphql/subscriptions";
import {BookingP, MeetingRoomBookingP} from "../types/PermissionHandling";
import {useMainApplicationContext} from "./useMainApplicationContext";
import {initializeManagableState} from "../Utils/Roles";


export function useBookingList(roomId: string, roomOrgUnitId: string,
                               buildingRoles: string[],
                               roomPlanRoles: string[],
                               dateISO?: string,): (BookingP | MeetingRoomBookingP)[] | [] {

    const {currentUser} = useMainApplicationContext();

    const {
        data: dataGetSeatBookings,
        refetch: refetchSeatBookings,
        subscribeToMore: subscribeToMoreBookings
    } = useQuery(gql(getSeatBookings), {
        variables: {
            input: {
                roomId: roomId,
                typeOfQuery: "bookingsByRoomAndDate",
                date: dateISO,
            },
            limit: 999
        }, skip: (roomId === "")
    })

    const {
        data: dataMeetRoomBookingsByRoomAndDate,
        refetch: refetchMeetingRoomBookings,
        subscribeToMore: subscribeToMoreMeetRoomBookings
    } = useQuery(gql(getMeetingRoomBookings), {
        variables: {
            roomIds: [roomId],
            date: dateISO
        }, skip: (roomId === "")
    });

    useEffect(() => {
        let subscriptions: (() => void)[] = []
        let timeout = setTimeout(() => {
                const unsubscribeOnChangeSeatBooking = subscribeToMoreBookings({
                    document: gql(onChangeSeatBooking),
                    updateQuery: (prev) => {
                        refetchSeatBookings().then().catch(error => {
                            console.error("refetch in useBookingList onChangeSeatBookingSubscription: " + JSON.stringify(error));
                        });
                        return prev;
                    },
                    onError: (error: any) => console.error("onChangeSeatBookingSubscription: " + JSON.stringify(error))
                });

                const unsubscribeOnChangeMeetingRoomBooking = subscribeToMoreMeetRoomBookings({
                    document: gql(onChangeMeetingRoomBooking),
                    updateQuery: (prev) => {
                        refetchMeetingRoomBookings().then().catch(error => {
                            console.error("refetch in useBookingList onChangeMeetingRoomBookingsSubscription: " + JSON.stringify(error));
                        });
                        return prev;
                    },
                    onError: (error: any) => console.error("onChangeMeetingRoomBookingsSubscription: " + JSON.stringify(error))
                });

                const unsubscribeOnUpdateRestrictedNeighborhoods = subscribeToMoreBookings({
                    document: gql(onUpdateRestrictedNeighborhoods),
                    updateQuery: (prev) => {
                        console.log("here")
                        refetchSeatBookings().then().catch(error => {
                            console.error("refetch in useBookingList onUpdateRestrictedNeighborhoodsSubscription seat booking: " + JSON.stringify(error));
                        });
                        return prev;
                    },
                    onError: (error: any) => console.error("onUpdateRestrictedNeighborhoodsSubscription seat booking: " + JSON.stringify(error))
                });

                const unsubscribeMeetingRoomOnUpdateRestrictedNeighborhoods = subscribeToMoreBookings({
                    document: gql(onUpdateRestrictedNeighborhoods),
                    updateQuery: (prev) => {
                        refetchMeetingRoomBookings().then().catch(error => {
                            console.error("refetch in useBookingList onUpdateRestrictedNeighborhoodsSubscription meeting room booking: " + JSON.stringify(error));
                        });
                        return prev;
                    },
                    onError: (error: any) => console.error("onUpdateRestrictedNeighborhoodsSubscription meeting room booking: " + JSON.stringify(error))
                });
                subscriptions = [
                    unsubscribeOnChangeSeatBooking, unsubscribeOnChangeMeetingRoomBooking, unsubscribeOnUpdateRestrictedNeighborhoods,
                    unsubscribeMeetingRoomOnUpdateRestrictedNeighborhoods
                ]
            }, SUBSCRIPTION_DELAY
        )

        function unsubscribe() {
            subscriptions.forEach(subscription => subscription())
            clearTimeout(timeout)
        }

        return () => unsubscribe();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomOrgUnitId, roomId, dateISO]);

    const bookings: Booking[] = useMemo(() => {
        return (dataGetSeatBookings?.getSeatBookings?.items ?? [])
            .map((b: Booking) => ({...b, __typename: "Booking"}))
    }, [dataGetSeatBookings]);

    const processedBookings: BookingP[] = useMemo(() => {
        return bookings
            .map(b => initializeManagableState(b, currentUser, buildingRoles, roomPlanRoles));
    }, [bookings, currentUser, buildingRoles, roomPlanRoles]);

    const meetRoomBookings: MeetingRoomBooking[] = useMemo(() => {
        return (dataMeetRoomBookingsByRoomAndDate?.getMeetingRoomBookings?.items ?? [])
            .map((b: MeetingRoomBooking) => ({...b, __typename: "MeetingRoomBooking"}))
    }, [dataMeetRoomBookingsByRoomAndDate]);

    const processedMeetRoomBookings: MeetingRoomBookingP[] = useMemo(() => {
        return meetRoomBookings
            .map(b => initializeManagableState(b, currentUser, buildingRoles, roomPlanRoles));
    }, [meetRoomBookings, currentUser, buildingRoles, roomPlanRoles]);

    return useMemo(() => {
        return [...processedBookings, ...processedMeetRoomBookings];
    }, [processedBookings, processedMeetRoomBookings]);
}

