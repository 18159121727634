import React, {useMemo, useState} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {
    bookingTabHeaderCell,
    bookingTabTableCell,
    getSortIcon,
    getSortOptionsByColumn,
    seatBookingSortColumns,
    StyledTabTableCell
} from "../../Utils/OwnBookingTabHelper";
import {formatDate, timestampToHourMinuteString} from "../../Utils/Helpers";
import {useTranslation} from "react-i18next";
import {Booking} from "../../API";
import {useCheckForBookingsWithTime} from "../../hooks/bookings/useCheckForBookingsWithTime";
import {DetailedSeatBooking} from "../../types/ExtendedSeatBookings";


interface Props {
    bookings: DetailedSeatBooking[]
    selectedBookings: DetailedSeatBooking[]
    onSelectBookings: (newSelection: DetailedSeatBooking[]) => void
}


export const OwnSeatBookingsSortableTable: React.FC<Props> = (props) => {

    const {bookings, selectedBookings, onSelectBookings} = props;

    const showTimeColumns = useCheckForBookingsWithTime(bookings);

    const {t, i18n} = useTranslation();
    const isGermanLocale = i18n.language.startsWith('de');

    const columnHeaders = [
        {label: t('date'), key: 'date'},
        {label: t("roomplan"), key: 'room'},
        {label: t('start-time'), key: 'timeBegin'},
        {label: t('end-time'), key: 'timeEnd'},
        {label: t('seat'), key: 'seatId'}
    ];

    const [sortOptions, setSortOptions] = useState<{ [key: string]: string }>({
        date: 'asc',
        room: 'default',
        timeBegin: 'default',
        timeEnd: 'default',
        seatId: 'default',
    });

    const sortedBookings = useMemo(() => {
        const selectedColumn = sortOptions.selectedColumn;
        const primarySortColumn = seatBookingSortColumns.find((sortItem) => sortItem.column === selectedColumn);
        const secondarySortColumns = seatBookingSortColumns.filter((sortItem) => sortItem.column !== selectedColumn);

        return bookings.slice().sort((a, b) => {
            const primarySortComparison = primarySortColumn?.compare(a[primarySortColumn.column]!, b[primarySortColumn.column]!) ?? 0;
            const sortOrder = sortOptions[selectedColumn] === 'desc' ? -1 : 1;
            const comparison = sortOrder * primarySortComparison;

            return comparison !== 0 ? comparison : secondarySortColumns.reduce((acc, sortColumn) => {
                if (acc === 0) {
                    return sortColumn.compare(a[sortColumn.column]!, b[sortColumn.column]!);
                }
                return acc;
            }, 0);
        });
    }, [sortOptions, bookings]);

    function handleRowClick(booking: DetailedSeatBooking) {
        const newSelectedRows = new Set(selectedBookings);
        if (newSelectedRows.has(booking)) {
            newSelectedRows.delete(booking);
        } else {
            newSelectedRows.add(booking);
        }
        onSelectBookings(Array.from(newSelectedRows));
    }

    function handleHeaderSortClick(column: string) {
        const newSortOptions = getSortOptionsByColumn(column, sortOptions);
        setSortOptions(newSortOptions);
    }

    return (
        <TableContainer>
            <Table style={{marginRight: 0, marginLeft: 0, maxWidth: '100%'}}>
                <TableHead>
                    <TableRow>
                        {columnHeaders.map((column) => {
                            if ((showTimeColumns || (column.key !== 'timeBegin' && column.key !== 'timeEnd'))) {
                                return (
                                    <TableCell
                                        key={column.key}
                                        onClick={() => handleHeaderSortClick(column.key)}
                                        style={bookingTabTableCell}
                                    >
                                        <div style={bookingTabHeaderCell}>
                                            {getSortIcon(sortOptions[column.key])}
                                            {column.label}
                                        </div>
                                    </TableCell>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedBookings.map((item: DetailedSeatBooking) => (
                        <TableRow
                            key={item.bookingId}
                            selected={selectedBookings.some(seatBooking => seatBooking.bookingId === item.bookingId)}
                            onClick={() => handleRowClick(item)}
                        >
                            <StyledTabTableCell>{formatDate(item.date, isGermanLocale)}</StyledTabTableCell>
                            <StyledTabTableCell>{item.roomName}</StyledTabTableCell>
                            {showTimeColumns && (
                                <StyledTabTableCell>{timestampToHourMinuteString(item.timeBegin)}</StyledTabTableCell>)}
                            {showTimeColumns && (
                                <StyledTabTableCell>{timestampToHourMinuteString(item.timeEnd)}</StyledTabTableCell>)}
                            <StyledTabTableCell>{item.seatId}</StyledTabTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}