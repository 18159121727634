import {Button, ButtonProps} from "@material-ui/core";
import Sit2GetherCloseIcon from "../../styles/Sit2GetherCloseIcon";
import React from "react";
import {useTranslation} from "react-i18next";

interface CloseButtonProps extends ButtonProps {
    children?: never;
}

const CloseButton = (props: CloseButtonProps) => {
    const {t} = useTranslation();
    return <Button color={"primary"}
                   variant={"contained"}
                   style={{wordBreak: "break-word"}}
                   startIcon={props.size !== 'small' && <Sit2GetherCloseIcon/>}
                   {...props}>
        {t("reporting-close-dialog-button-text")}
    </Button>;
}
CloseButton.muiName = 'Button';


export default CloseButton;
