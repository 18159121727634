import {Booking} from "../../API";
import React, {useEffect, useState} from "react";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useTranslation} from "react-i18next";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";
import {EditSeatBookingDialogActions} from "./EditSeatBookingDialogActions";
import {OptionalAlert} from "../Alert/OptionalAlert";
import {DesktopDialogContent} from "./DesktopDialogContent";
import {MobileDialogContent} from "./MobileDialogContent";
import {BookingFor} from "../../Utils/Enums";
import {useUpdateSeatBooking} from "../../hooks/bookings/useUpdateSeatBooking";
import {useErrorContext} from "../../hooks/useErrorContext";
import {
    adjustDayjsMilliseconds,
    combineFormAndBookingData,
    defaultData,
    EditableData, EditableDataTimeNotNull,
    validateSeatBookingEditData
} from "./seat-booking-edit-dialog-utils";
import dayjs from "dayjs";

interface Props {
    bookingToEdit?: Booking | null,
    otherBookingsForTimeWindow: Booking[],
    isOpen: boolean
    handleClose: () => void
    isTimeBookingActive: boolean
}

export const SeatBookingEditDialog: React.FC<Props> = (props) => {
    const {isOpen, bookingToEdit, otherBookingsForTimeWindow, handleClose, isTimeBookingActive} = props;
    const {t} = useTranslation();
    const {isNoFullscreen} = useDeviceMediaType();
    const [formDataIsValid, setFormDataIsValid] = useState(false);

    const {reportError} = useErrorContext();
    const updateSeatBooking = useUpdateSeatBooking();

    const [editableData, setEditableData] = useState<EditableData>(defaultData);
    const [alertText, setAlertText] = useState("");

    function handleEditableDataChange(newData: Partial<EditableData>) {
        setEditableData(prevState => {
                const newEditableData = {...prevState, ...newData}
                if (newEditableData.bookingFor === BookingFor.BOOKING_FOR_ME) {
                    newEditableData.bookingForText = ""; // reset field
                }
                return newEditableData;
            }
        );
    }

    useEffect(function validateFormData() {
        const {isValid, errorKeys} = validateSeatBookingEditData(editableData, bookingToEdit, otherBookingsForTimeWindow);
        setFormDataIsValid(isValid);

        const numberOfErrors = errorKeys.length;
        if (numberOfErrors > 0) {
            setAlertText(t(errorKeys[0]));
        } else {
            setAlertText("");
        }
    }, [t, bookingToEdit, editableData, otherBookingsForTimeWindow]);

    useEffect(function bookingToEditChanged() {
        if (bookingToEdit == null) {
            handleEditableDataChange(defaultData);
            return;
        }
        const beginTime = (bookingToEdit.timeBegin == null) ? null : adjustDayjsMilliseconds(dayjs(bookingToEdit.timeBegin));
        const endTime = (bookingToEdit.timeEnd == null) ? null : adjustDayjsMilliseconds(dayjs(bookingToEdit.timeEnd));

        const bookingForText = bookingToEdit.bookingFor ?? "";
        const bookingFor = bookingForText === "" ? BookingFor.BOOKING_FOR_ME : BookingFor.BOOKING_FOR_OTHERS;

        const bookingData: EditableData = {
            beginTime: beginTime,
            endTime: endTime,
            bookingFor: bookingFor,
            bookingForText: bookingForText
        }

        handleEditableDataChange(bookingData);
    }, [bookingToEdit]);

    function handleSave() {
        if (bookingToEdit === null || bookingToEdit === undefined) {
            return;
        }
        const {isValid} = validateSeatBookingEditData(editableData, bookingToEdit, otherBookingsForTimeWindow);
        if (!isValid) {
            return;
        }
        const dataToSave = combineFormAndBookingData(editableData as EditableDataTimeNotNull, bookingToEdit);
        updateSeatBooking(dataToSave)
            .then(() => handleClose())
            .catch((e) => {
                reportError(e, t("sb-edit-save-failed"), "SeatBookingEditDialog handleSave");
            });
    }

    const bookingPresent = !(bookingToEdit === null || bookingToEdit === undefined);
    const saveButtonDisabled = !formDataIsValid;

    return (
        <Dialog maxWidth="md" open={isOpen} scroll={"paper"} id={"my-dialog"}>
            <OptionalAlert showAlert={alertText !== ""} alertText={alertText}/>
            <DialogTitle>{t("edit-seat-booking-title")}</DialogTitle>
            {bookingPresent && <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
                    {isNoFullscreen ?
                        <MobileDialogContent
                            isTimeBookingActive={isTimeBookingActive}
                            otherBookings={otherBookingsForTimeWindow}
                            editableData={editableData}
                            onDataChange={handleEditableDataChange}/> :
                        <DesktopDialogContent
                            isTimeBookingActive={isTimeBookingActive}
                            otherBookings={otherBookingsForTimeWindow}
                            editableData={editableData}
                            onDataChange={handleEditableDataChange}/>
                    }
                </LocalizationProvider>
            </DialogContent>}
            <EditSeatBookingDialogActions
                handleSave={handleSave}
                saveButtonDisabled={saveButtonDisabled}
                handleClose={handleClose}/>
        </Dialog>
    );
}