import {Booking} from "../../API";
import {Dayjs} from "dayjs";
import React from "react";
import {Box} from "@mui/material";
import {columnStyleFixedWidth} from "../../Utils/commons";
import {BookingSettings} from "./BookingSettings";
import {SingleDayTimeWindowTableComponent} from "../BookingDialog/SingleDayTimeWindowTableComponent";
import {EditableData} from "./seat-booking-edit-dialog-utils";

interface PropsDesktop {
    otherBookings: Booking[],
    editableData: EditableData
    onDataChange: (newData: Partial<EditableData>) => void
    isTimeBookingActive: boolean
}

export const DesktopDialogContent: React.FC<PropsDesktop> = (props) => {
    const {
        otherBookings,
        editableData,
        onDataChange,
        isTimeBookingActive
    } = props;

    function handleClickOnTimeWindow(start: Dayjs, end: Dayjs) {
        onDataChange({beginTime: start, endTime: end});
    }

    return (
        <Box className={"dialogTab"}>
            <div style={{...columnStyleFixedWidth, gap: "16px"}}>
                <BookingSettings data={editableData} onDataChange={onDataChange} timeActive={isTimeBookingActive}/>
            </div>
            {isTimeBookingActive &&
                <>
                    <div className={"verticalLine"}/>
                    <div style={columnStyleFixedWidth}>
                        <SingleDayTimeWindowTableComponent
                            bookings={otherBookings}
                            onTimeWindowClick={handleClickOnTimeWindow}
                        />
                    </div>
                </>
            }
        </Box>);
}